<template>
  <div v-if="secaoSelecionada">
    <BaseModal
      @close="fechar()"
      :classesAdicionaisModal="'modal-balanco rounded overflow-x-hidden w-90 w-sm-100 position-relative'"
      :tituloModal="'ACOMPANHAMENTO BALANÇO'"
      :classesAdicionaisHeader="'d-flex align-items-center cabecalho-modal-customizado-lmx py-1 px-2'"
      :classesAdicionaisFooter="'d-none'"
      :classesAdicionaisBody="'py-2 overflow-x-hidden'"
      id="modalAcompanharBalanco"
    >
      <div slot="body">
        <div class="mt-3 mr-5 mb-3 ml-5">
          <div class="row">
            <h3>
              {{ secaoSelecionada.Descricao }}
              {{ secaoSelecionada.Etapa ? ' - Etapa:' + secaoSelecionada.Etapa : '' }}
            </h3>
          </div>
          <div class="row mb-0">
            <p class="mb-0 mt-2">
              Total de Produtos: <strong>{{ totalProdutosSecao }}</strong>
            </p>
          </div>
          <div class="row">
            <p class="mb-1 mt-0">
              Situação: <strong>{{ obterDescricaoStatusSecao(secaoSelecionada.IdStatusSecao) }}</strong>
            </p>
          </div>
          <div class="row">
            <!-- Tabela -->
            <div class="row flex-grow-1 mt-2">
              <div class="col-12">
                <div class="table-responsive mb-3">
                  <table class="table table-sm table-striped table-hover lmx-tabela-relatorio" cellspacing="1">
                    <thead>
                      <tr>
                        <th v-if="secaoSelecionada.ControlaLocalizacao">Localização</th>
                        <th class="text-center align-middle">Código</th>
                        <th class="text-center align-middle">Descrição</th>
                        <th class="text-center align-middle">Quantidade</th>
                        <th class="text-center align-middle" v-if="balancoComLote">Lote</th>
                        <th class="text-center align-middle" v-if="balancoComDataFabricacao">Data de Fabricação</th>
                        <th class="text-center align-middle" v-if="balancoComDataVencimento">Data de Validade</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="(produto, index) in produtos">
                        <tr :key="index">
                          <td v-if="secaoSelecionada.ControlaLocalizacao">{{ produto.SiglaLocalizacao }}</td>
                          <td class="text-center">{{ produto.CodigoProduto }}</td>
                          <td class="text-center">{{ produto.Nome }}</td>
                          <td class="text-center align-middle">
                            <span v-if="balancoViaUploadArquivo">{{ formatarValorQtde(produto.Quantidade) }}</span>
                            <InputNumber 
                              v-else
                              v-model="produto.Quantidade"
                              :precisao="casasDecimaisQtdeProduto"
                              @input="atualizarTotalProdutosSecao()" 
                              class="px-1" />
                          </td>
                          <template v-if="(produto.Lotes || []).length == 1">
                            <td class="text-center" v-if="balancoComLote">{{ produto.Lotes[0].LoteFormatado }}</td>
                            <td class="text-center" v-if="balancoComDataFabricacao">{{ produto.Lotes[0].DataFabricacaoFormatada }}</td>
                            <td class="text-center" v-if="balancoComDataVencimento">{{ produto.Lotes[0].DataVencimentoFormatada }}</td>
                          </template>
                          <template v-else-if="(produto.Lotes || []).length == 0">
                            <td class="text-center" v-if="balancoComLote">-</td>
                            <td class="text-center" v-if="balancoComDataFabricacao">-</td>
                            <td class="text-center" v-if="balancoComDataVencimento">-</td>
                          </template>
                          <template v-else>
                            <td colspan="3"></td>
                          </template>
                        </tr>
                        <template v-if="(produto.Lotes || []).length > 1">
                          <tr v-for="(dadosLote, idx) in produto.Lotes" :key="'lote_' + idx">
                            <td colspan="2">
                              <div v-if="idx == 0"
                                class="invalid-feedback d-block">
                                <span>{{ errosValidacao[produto.CodigoProduto] }}</span>
                              </div>
                            </td>
                            <td class="text-center align-middle">
                              <span v-if="balancoViaUploadArquivo">{{ formatarValorQtde(dadosLote.Quantidade) }}</span>
                              <InputNumber 
                                v-else
                                v-model="dadosLote.Quantidade"
                                :precisao="casasDecimaisQtdeProduto"
                                class="px-1" />
                            </td>
                            <td class="text-center" v-if="balancoComLote">{{ dadosLote.LoteFormatado }}</td>
                            <td class="text-center" v-if="balancoComDataFabricacao">{{ dadosLote.DataFabricacaoFormatada }}</td>
                            <td class="text-center" v-if="balancoComDataVencimento">{{ dadosLote.DataVencimentoFormatada }}</td>
                          </tr>
                        </template>
                      </template>
                    </tbody>
                  </table>
                </div>
                <div class="col-12 text-right px-2">
                  <button v-if="balancoViaUploadArquivo" type="button" class="btn btn-default rounded" @click="fechar()"
                    >Fechar</button
                  >
                  <button v-else type="button" class="btn btn-primary rounded" @click="alterarQuantidadeProdutoSecao"
                    >Salvar</button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
  </div>
</template>

<script>
  import Vue from 'vue';
  import { EventBus } from '@/common/eventBus';

  import swal from '@/common/alerts';
  import loading from '@/common/loading';
  import dateHelper from '@/common/date';

  import InputNumber from '@/components/shared/input/InputNumber.vue';

  export default {
    name: 'ModalEditarSecaoBalanco',

    components: {
      InputNumber,
    },

    props: {
      secaoSelecionada: {
        type: Object,
        default: () => {},
      },
      balancoSelecionado: {
        type: Object,
        default: () => {
          return { IdBalanco: 0 };
        },
      },
    },

    data() {
      return {
        codigoDeBarrasProduto: '',
        totalProdutosSecao: 0,
        errosValidacao: {},
      };
    },

    computed: {
      produtos() {
        return this.secaoSelecionada.ProdutosQuantidades.map((prod) => {
          return {
            ...prod,
            Lotes: prod.Lotes.map((lote) => {
              return {
                ...lote,
                DataFabricacaoFormatada: lote.DataFabricacao ? dateHelper.toStringPtBr(lote.DataFabricacao) : '-',
                DataVencimentoFormatada: lote.DataVencimento ? dateHelper.toStringPtBr(lote.DataVencimento) : '-',
                LoteFormatado: lote.Lote ? lote.Lote : '-',
              };
            }),
          };
        });
      },
      balancoViaUploadArquivo() {
        return this.secaoSelecionada.BalancoUploadArquivo;
      },
      balancoComLote() {
        return this.secaoSelecionada.ProdutosQuantidades.some((s) => s.Lotes.length > 0);
      },
      balancoComDataFabricacao() {
        return this.secaoSelecionada.ProdutosQuantidades.some((s) => s.Lotes.some((l) => l.DataFabricacao));
      },
      balancoComDataVencimento() {
        return this.secaoSelecionada.ProdutosQuantidades.some((s) => s.Lotes.some((l) => l.DataVencimento));
      },
      casasDecimaisQtdeProduto() {
				return this.$store.state.balanco.parametroCasasDecimaisQuantidadeProduto || 0;
			},
    },

    watch: {},

    methods: {
      fechar() {
        EventBus.$emit('fechar-modal-editar-secao');
      },
      obterDescricaoStatusSecao(idStatus) {
        switch (idStatus) {
          case 4:
            return 'Pendente';
          case 5:
            return 'Em conferência';
          case 6:
            return 'Concluído';
          default:
            return '';
        }
      },
      montaCommandProdutos() {
        const commandProdutos = this.produtos.map(function (p) {
          return {
            CodigoProduto: p.CodigoProduto,
            IdProdutoLocalizacaoDeposito: p.IdProdutoLocalizacaoDeposito,
            Quantidade: p.Quantidade,
            Lotes: p.Lotes,
          };
        });
        return commandProdutos;
      },
      alterarQuantidadeProdutoSecao() {
        if (!this.validarQuantidadeLotes()) return;

        const { IdBalanco } = this.balancoSelecionado;

        const command = {
          Produtos: this.montaCommandProdutos(),
          IdSecao: this.secaoSelecionada.Id,
          Etapa: this.secaoSelecionada.Etapa,
          IdBalanco,
        };

        loading.exibir();

        return this.$store
          .dispatch('balanco/alterarQuantidadeProdutoSecao', command)
          .then((jsonResponse) => {
            EventBus.$emit('obter-dados-iniciais-acompanhamento', 'Quantidades atualizadas com sucesso!');
            return Promise.resolve(jsonResponse);
          })
          .catch((erro) => {
            let mensagemErro = '';

            if (erro.response) {
              if (erro.response.status == 400) mensagemErro = erro.data;
              else mensagemErro = erro.message;
            } else mensagemErro = erro;

            swal.exibirMensagemErro(mensagemErro);
            return Promise.reject(mensagemErro);
          })
          .finally(() => {
            loading.ocultar();
          });
      },
      atualizarTotalProdutosSecao() {
        if (this.produtos && this.produtos.length !== 0) {
          let quantidadeTotalProdutosSecao = 0;
          this.produtos.forEach((produtoSecao) => {
            quantidadeTotalProdutosSecao += Number(produtoSecao.Quantidade);
          });
          this.$set(this, 'totalProdutosSecao', this.formatarValorQtde(quantidadeTotalProdutosSecao));
        } else this.$set(this, 'totalProdutosSecao', 0);
      },
      formatarValorQtde(value) {
        var decimais = this.casasDecimaisQtdeProduto;
        return new Intl.NumberFormat('pt-BR', {
          maximumFractionDigits: decimais,
          roundingMode: "trunc",
        }).format(parseFloat(value.toFixed(decimais)));
      },
      validarQuantidadeLotes() {
        var self = this;

        var possuiErroValidacaoLote = false;
        self.produtos.forEach(produto => {
          if (produto.Lotes.length > 1) {
            var quantidadeTotalLotes = produto.Lotes.reduce((acc, curr) => acc + Number(curr.Quantidade), 0);

            let erroValidacao = "";
            if (quantidadeTotalLotes < produto.Quantidade) erroValidacao = "menor";
            if (quantidadeTotalLotes > produto.Quantidade) erroValidacao = "maior";

            if (erroValidacao) {
              possuiErroValidacaoLote = true;
              self.$set(self.errosValidacao, produto.CodigoProduto, "Quantidade informada dos lotes é " + erroValidacao + " que a quantidade total de itens do produto.");
            } else {
              self.$set(self.errosValidacao, produto.CodigoProduto, "");
            }
          } else if (produto.Lotes.length == 1) {
            produto.Lotes[0].Quantidade = produto.Quantidade;
          }
        });

        return !possuiErroValidacaoLote;
      }
    },

    mounted() {
      this.atualizarTotalProdutosSecao();
    },
  };
</script>

<style scoped>
  .secao-selecionada {
    background-color: #ffb200 !important;
  }
</style>
