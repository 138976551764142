<template>
  <div>
    <BaseModal
      @close="fechar()"
      :classesAdicionaisModal="'modal-balanco rounded overflow-x-hidden w-60 w-sm-100 position-relative'"
      :tituloModal="'EXECUÇÃO BALANÇO'"
      :classesAdicionaisBackDrop="'align-items-start'"
      :classesAdicionaisFooter="'d-none'"
      :classesAdicionaisBody="'py-2 overflow-x-hidden'"
      id="modalExecucaoBalanco"
    >
      <div slot="body">
        <div class="row mt-3 mb-2" :class="{ 'mb-5': !isUploadFile }">
          <div v-if="mostrarSecoes" class="d-flex justify-content-center col-sm-12">
            <label class="input-required label-cadastro-balanco">Seções</label>
          </div>
          <div v-if="mostrarSecoes" class="d-flex justify-content-center col-sm-12" ref="secoes">
            <div class="d-flex justify-content-center col-sm-8">
              <Multiselect
                v-model="secaoSelecionada"
                :options="Secoes"
                :multiple="false"
                :allow-empty="true"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="false"
                :custom-label="customLabelSecao"
                placeholder="Selecione"
                option-Height="10"
                selectedLabel="Selecionado"
                selectLabel="Selecionar"
                deselectLabel="Remover"
                label="Descricao"
                :preselect-first="true"
                :disabled="!habilitarSelect"
              >
                <template slot="selection" slot-scope="select">
                  <span class="multiselect__single" v-if="select.values.length == 1 &amp;&amp; !select.isOpen">
                    <div v-for="(item, idx) in select.values" :key="idx" style="float: left">
                      {{ item.Id + '-' + item.Descricao }}
                    </div>
                  </span>
                </template>
                <span slot="noResult">Nenhum registro encontrado</span>
                <span slot="noOptions">Selecione um registro</span>
              </Multiselect>
            </div>
          </div>

          <div class="col-sm-12 d-flex justify-content-center w-100 mt-1">
            <p v-if="habilitarQrCode && !isUploadFile" class="text-center w-100 mt-2">
              Utilize um leitor de QR Code do seu celular ou tablet para executar o balanço através do seu dispositivo:
            </p>
            <p v-else-if="Secoes.length == 0" class="text-center w-100 mt-2"
              >Todas as seções deste balanço já foram concluídas.</p
            >
            <p v-else-if="isUploadFile && secaoSelecionada == null" class="msg text-center w-100 mt-2"
              >Escolha uma seção para importar o arquivo.</p
            >
            <p v-else-if="!isUploadFile && secaoSelecionada == null" class="msg text-center w-100 mt-2"
              >Escolha uma seção para gerar o QR Code.</p
            >
          </div>
          <div class="col-sm-15 d-flex justify-content-center w-100 mt-2">
            <div class="col-5" v-if="habilitarQrCode">
              <div
                v-if="!isUploadFile"
                class="card card-personalizado h-70 w-100 m-1"
                data-toggle="modal"
                data-target="#executarModalQrCode"
              >
                <div class="card-body">
                  <div class="d-flex justify-content-center w-100">
                    <QrCode :value="linkUrl" :width="220"></QrCode>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Botão-->
          <div class="d-flex justify-content-center col-sm-12 mt-2" v-if="habilitarQrCode && !isUploadFile">
            <button type="button text-center" class="btn btn-primary mr-1" @click="avancarExecucaoSecao()">
              <span>CONTINUAR EXECUÇÃO DO BALANÇO</span>
            </button>
          </div>
          <upload-arquivo
            :dados-execucao-balanco="{ ...dadosExecucaoBalanco, SecaoSelecionada: secaoSelecionada }"
            class="d-flex justify-content-center col-sm-12 m-0"
            v-if="isUploadFile && secaoSelecionada"
          >
          </upload-arquivo>
        </div>
      </div>
    </BaseModal>
  </div>
</template>

<script>
  import QrCode from 'vue-qrcode';
  import Multiselect from 'vue-multiselect';
  import { EventBus } from '@/common/eventBus';
  import UploadArquivo from './UploadArquivo.vue';
  export default {
    name: 'ExecucaoQrCode',

    components: {
      QrCode,
      Multiselect,
      UploadArquivo,
    },

    props: {
      dadosExecucaoBalanco: {
        type: Object,
        default: null,
      },
      isUploadFile: {
        type: Boolean,
        default: false,
      },
    },

    mounted() {
      this.registrarEventos();
      this.registrarDadosApi();
      this.obterSecoesDoBalanco();
    },

    data() {
      return {
        errosValidacao: {},
        tokenApi: null,
        urlApi: null,
        secaoSelecionada: null,
        Secoes: [],
        statusSecao: {
          pendente: 4,
          emConferencia: 5,
          concluido: 6,
        },
      };
    },

    computed: {
      habilitarQrCode() {
        var self = this;
        return self.secaoSelecionada;
      },
      habilitarSelect() {
        var self = this;
        return self.Secoes.length > 0;
      },
      mostrarSecoes() {
        var self = this;
        return (
          !self.dadosExecucaoBalanco || (self.dadosExecucaoBalanco.Secao && !self.dadosExecucaoBalanco.Secao.EsconderSeletor)
        );
      },
      linkUrl() {
        return (
          this.urlBase() +
          '/executarSecao?idBalanco=' +
          this.dadosExecucaoBalanco.IdBalanco +
          '&idSessao=' +
          this.secaoSelecionada.Id +
          '&nomeSecao=' +
          this.secaoDescricao() +
          '&etapa=' +
          this.secaoSelecionada.Etapa +
          '&token=' +
          this.tokenApi +
          '&urlApi=' +
          this.urlApi
        );
      },
    },

    watch: {},

    methods: {
      groupBy(objectArray, property) {
        return objectArray.reduce(function (acc, obj) {
          var key = obj[property];
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(obj);
          return acc;
        }, {});
      },
      urlBase() {
        var urlBase = window.location.href;
        return urlBase;
      },
      obterSecoesDoBalanco() {
        var self = this;
        if (self.dadosExecucaoBalanco.Secao)
          if (self.dadosExecucaoBalanco.Secao.Etapa) {
            self.secaoSelecionada = self.dadosExecucaoBalanco.Secoes.find((secao) => {
              return secao.Id == self.dadosExecucaoBalanco.Secao.Id && secao.Etapa == self.dadosExecucaoBalanco.Secao.Etapa;
            });
          } else {
            self.secaoSelecionada = self.dadosExecucaoBalanco.Secoes.find((secao) => {
              return secao.Id == self.dadosExecucaoBalanco.Secao.Id;
            });
          }

        var grupoSecao = self.groupBy(self.dadosExecucaoBalanco.Secoes, 'Id');

        self.Secoes = self.dadosExecucaoBalanco.Secoes.filter(function (secao) {
          return (
            secao.IdStatusSecao != self.statusSecao.concluido &&
            (!secao.Etapa ||
              secao.Etapa < 3 ||
              (grupoSecao[secao.Id].find(function (e) {
                return e.Etapa == 1;
              }).IdStatusSecao == self.statusSecao.concluido &&
                grupoSecao[secao.Id].find(function (e) {
                  return e.Etapa == 2;
                }).IdStatusSecao == self.statusSecao.concluido))
          );
        });
      },
      fechar() {
        EventBus.$emit('fechar-modal-execucao-qrcode');
      },
      registrarDadosApi() {
        this.tokenApi = this.$store.state.autenticacao.LmxAuthToken;
        this.urlApi = localStorage.getItem('url_suprimentos_api');
      },
      obterDescricaoStatusSecao: function (idStatus) {
        switch (idStatus) {
          case 4:
            return 'Pendente';
          case 5:
            return 'Em conferência';
          case 6:
            return 'Concluído';
          default:
            return '';
        }
      },
      secaoDescricao() {
        return this.secaoSelecionada.Descricao.replace(/ /g, '-');
      },
      customLabelSecao(obj) {
        var self = this;
        return obj.Id + '- ' + obj.Descricao + ' (' + self.obterDescricaoStatusSecao(obj.IdStatusSecao) + ')';
      },
      avancarExecucaoSecao() {
        let params = {
          idBalanco: this.dadosExecucaoBalanco.IdBalanco,
          idSessao: this.secaoSelecionada.Id,
          nomeSecao: this.secaoDescricao(),
          etapa: this.secaoSelecionada.Etapa,
          token: this.tokenApi,
          urlApi: this.urlApi,
        };
        this.$router.push({ name: 'ExecucaoSecao', query: params });
      },
      registrarEventos() {
        EventBus.$on('avancar-execucao-secao', () => this.avancarExecucaoSecao());
      },
    },
  };
</script>

<style scoped>
  .card-personalizado {
    border: 1px solid #999;
    border-radius: 4px;
    background: #eee;
    text-align: center;
    cursor: pointer;
  }
  .msg {
    margin-bottom: 100px;
  }
</style>
