<template>
  <div>
    <BaseModal
      @close="fechar()"
      :classesAdicionaisModal="'modal-balanco rounded overflow-x-hidden w-60 w-sm-100 position-relative'"
      :tituloModal="'ESTORNO BALANÇO'"
      :classesAdicionaisHeader="'d-flex align-items-center cabecalho-modal-customizado-lmx py-1 px-2'"
      :classesAdicionaisFooter="'d-none'"
      :classesAdicionaisBody="'py-2 overflow-x-hidden'"
      id="modalEstornoBalanco"
    >
      <div slot="body">
        <div class="mx-3 my-4">
          <div class="row">
            <div class="d-flex justify-content-between align-items-center flex-grow-1">
              <h2 style="font-size: 22px">Balanço {{ balancoSelecionado.IdBalanco }}: {{ balancoSelecionado.Descricao }}</h2>
            </div>
          </div>
          <div class="row">
            <div class="d-flex justify-content-between align-items-center flex-grow-1">
              <h2 style="font-size: 18px">
                Total de Produtos do Balanço: <b>{{ totalProdutos.toLocaleString() }} </b>
              </h2>
            </div>
          </div>
          <div class="row">
            <label>Seções</label>
          </div>
          <!-- Tabela -->
          <div class="row">
            <div class="row flex-grow-1 mt-2">
              <div class="col-12">
                <div class="table-responsive mb-3">
                  <table class="table table-sm table-striped table-hover lmx-tabela-relatorio" cellspacing="1">
                    <thead>
                      <tr>
                        <th class="text-center align-middle">Seção</th>
                        <th class="text-center align-middle">Total de Produtos</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(estorno, index) in listaSecaoEQuantidadeEstorno" :key="index">
                        <td class="align-middle text-center">
                          <span>{{ estorno.Secao }} </span>
                        </td>
                        <td class="align-middle text-center">
                          <span>{{ estorno.QuantidadeProdutos.toLocaleString() }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="h-100 w-100 lmx-tela-relatorio-div-nenhum-registro" v-if="listaSecaoEQuantidadeEstorno.length == 0">
                  <div class="text-center">
                    <i class="fa fa-exclamation text-primary fa-2x"></i>
                    <h3>Nenhum balanço encontrado</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary shadow-sm" @click="estornarBalanco">
              <span>Confirmar Estorno</span>
            </button>
            <button class="btn btn-default shadow-sm" @click="fechar">
              <span>Cancelar</span>
            </button>
          </div>
        </div>
      </div>
    </BaseModal>
  </div>
</template>

<script>
  import Vue from 'vue';
  import swal from '@/common/alerts';
  import loading from '@/common/loading';
  import { EventBus } from '@/common/eventBus';

  export default {
    name: 'EstornoBalanco',
    data() {
      return {
        totalProdutos: 0,
        listaSecaoEQuantidadeEstorno: [],
      };
    },
    components: {},
    props: {
      balancoSelecionado: {
        type: Object,
        default: () => {
          return {
            IdBalanco: 0,
            Descricao: '',
          };
        },
      },
    },
    mounted() {
      this.listaSecaoEQuantidadeEstorno = this.$store.state.balanco.listaSecaoEQuantidadeEstorno;

      this.listaSecaoEQuantidadeEstorno.forEach((quantidade) => {
        this.totalProdutos += quantidade.QuantidadeProdutos;
      });
    },

    watch: {},

    computed: {},

    created() {},

    methods: {
      fechar() {
        EventBus.$emit('fechar-modal-estorno');
      },
      estornarBalanco() {
        loading.exibir();

        var idBalanco = this.balancoSelecionado.IdBalanco;

        return this.$store
          .dispatch('balanco/EstornarBalanco', idBalanco)
          .then((jsonResponse) => {
            if (jsonResponse != '') {
              swal.exibirMensagemSucesso(jsonResponse.Notifications[0], () => {
                this.fechar();
                EventBus.$emit('recarregar-painel-balanco');
              });
            } else {
              swal.exibirMensagemSucesso('Estorno do balanco <b>' + idBalanco + '</b> realizado com sucesso!', () => {
                this.fechar();
                EventBus.$emit('recarregar-painel-balanco');
                EventBus.$emit('desabilitar-Filtro-Finalizados');
              });
            }

            return Promise.resolve();
          })
          .catch(function (erro) {
            let mensagemErro = '';

            if (erro.response) {
              if (erro.response.status == 400) mensagemErro = erro.data;
              else mensagemErro = erro.message;
            } else mensagemErro = erro;

            swal.exibirMensagemErro(mensagemErro);
            return Promise.reject(mensagemErro);
          })
          .finally(function () {
            loading.ocultar();
          });
      },
    },
  };
</script>

<style scoped></style>
