<template>
  <div class="row mb-2 mt-0">
    <!--loading upload -->
    <div id="loading-estoque-loading-overlay" v-if="isLoading">
      >
      <div id="loading-estoque-loading-center-spinner">
        <div id="loading-estoque-spinner">
          <div id="loading-estoque-double-bounce1"></div>
          <div id="loading-estoque-double-bounce2"></div>
        </div>
      </div>
    </div>

    <div class="col-12 d-flex justify-content-center w-100">
      <div class="content shadow-sm bg-white shadow-hover card-panel w-100 py-0">
        <div class="row my-0 py-0">
          <div class="col-12 text-center mt-2">
            <h6 class="text-muted text-uppercase"> Insira Itens através de um arquivo </h6>
            <img src="@/assets/Images/linx-icon-ilustrativo/upload.png" alt="check" />
            <p class="title fz-95"><small> (Extensões permitidas: (*.TXT) e (*.CSV) ) </small></p>
          </div>
        </div>

        <div class="d-flex flex-column mb-2 text-center align-items-center">
          <div class="d-flex w-100">
            <div class="w-30">
              <label class="input-required fz-90">Enviar Arquivo com</label>
            </div>
            <div class="w-100">
              <Multiselect
                v-model="filtroTipoArquivo.selecionado"
                :options="filtroTipoArquivo.opcoes"
                :multiple="false"
                :close-on-select="true"
                placeholder="Selecione..."
                select-label="Selecionar"
                deselect-label="Remover"
                selected-label="Selecionado"
                label="Descricao"
                open-direction="bottom"
                @input="cabecalhoTipoArquivo()"
              >
                <template slot="selection" slot-scope="select">
                  <span class="multiselect__single" v-if="select.values.length == 1 && !select.isOpen">
                    {{ select.values[0].Descricao }}
                  </span>
                  <span class="multiselect__single" v-if="select.values.length > 1 && !select.isOpen">
                    {{ select.values.length }} selecionados
                  </span>
                </template>
                <span slot="noResult">Nenhum registro encontrado</span>
                <span slot="noOptions">Selecione um Status</span>
              </Multiselect>
            </div>
          </div>
          <div class="fz-80 text-muted mt-1">
            <div class="border px-1"><b>Exemplo.txt</b></div>
            <div class="border px-1">232302301<b>;</b>1<b>;</b>9001<b>;</b>21102021<b>;</b>22102024</div>
            <div class="border px-1">222302323<b>;</b>5<b>;</b>9001<b>;</b>21102021<b>;</b>22102024</div>
            <div class="border px-1">4002302345<b>;</b>22<b>;</b>9003<b>;</b>24122020<b>;</b>22102023</div>
            <span class="fz-70">Obs.: Data no formato "DDMMAAAA"</span>
          </div>
        </div>

        <div class="row" v-if="nomeArquivo">
          <div class="col-8 p-0">
            <div class="btn btn-default w-100">
              {{ nomeArquivo }}
            </div>
          </div>
          <div class="col-4 p-0">
            <button class="btn btn-danger w-100" @click="removerArquivo"> Remover arquivo </button>
          </div>
        </div>
        <div class="row" v-else>
          <label :disabled="!filtroTipoArquivo.selecionado" class="btn btn-primary w-100">
            Clique aqui e escolha um arquivo
            <input
              :disabled="!filtroTipoArquivo.selecionado"
              type="file"
              id="csvFileInput"
              @change="carregarArquivo"
              ref="arquivos"
              hidden
              accept=".csv,.txt"
            />
          </label>
        </div>
      </div>
    </div>
    <div class="col-12 d-flex justify-content-end w-100 mt-4">
      <button type="button" class="btn btn-default mr-2" data-dismiss="modal" @click="fechar"> Fechar </button>
      <button type="button" class="btn btn-primary" data-dismiss="modal" @click="enviarArquivo" :disabled="!nomeArquivo">
        Confirmar
      </button>
    </div>

    <BaseModal
      @close="fecharModalCriticas()"
      :classesAdicionaisModal="'modal-balanco rounded overflow-x-hidden w-50 w-sm-100 position-relative'"
      :tituloModal="'Atenção!'"
      :classesAdicionaisBackDrop="'align-items-start'"
      :classesAdicionaisFooter="'d-none'"
      :classesAdicionaisBody="'py-2 overflow-x-hidden'"
      id="modalCriticasArquivo"
      v-if="modalCriticas.isHidden"
    >
      <!--Criticas-->
      <div slot="body">
        <div class="d-flex">
          <img src="@/assets/Images/linx-icon-ilustrativo/alert.png" alt="check" />
          <div class="d-flex flex-column justify-content-center">
            <h3 class="text-primary my-0">{{ qteProdutosDivergencias }} PRODUTO(S) COM DIVERGÊNCIA(S)</h3>
            <span>Os produtos abaixo apresentaram críticas</span>
          </div>
        </div>
        <!-- Tabela -->
        <div class="flex-grow-1 mt-2 justify-content-center">
          <div class="table-responsive mb-3">
            <table class="table table-sm table-striped table-hover lmx-tabela-relatorio" cellspacing="1">
              <thead>
                <tr>
                  <th class="text-center align-middle">Linhas</th>
                  <th class="text-center align-middle">Produto</th>
                  <th class="text-center align-middle" v-if="balancoComLote">Lote</th>
                  <th class="text-center align-middle">Mensagem de Crítica</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(produto, index) in modalCriticas.linhasComCriticas" :key="index">
                  <td class="text-center">{{ produto.Linhas }}</td>
                  <td class="text-center">{{ produto.Codigo }}</td>
                  <td class="text-center" v-if="balancoComLote">{{ produto.Lote }}</td>
                  <td class="text-center">{{ produto.MensagemCritica }}</td>
                </tr>
              </tbody>
            </table>
            <div class="label-orange text-right">Verifique o arquivo e realize uma nova importação.</div>
          </div>
        </div>
      </div>
    </BaseModal>
  </div>
</template>

<script>
  import { EventBus } from '@/common/eventBus';
  import Multiselect from 'vue-multiselect';
  import swal from '@/common/alerts';
  import { HEADER_ARQUIVO_UPLOAD, CODIGOS_ARQUIVO_UPLOAD } from '@/core/enums/tipos-arquivo-upload.enum';

  export default {
    name: 'ExecucaoBalanco',
    components: { Multiselect },

    props: {
      dadosExecucaoBalanco: {
        type: Object,
        default: null,
      },
    },

    mounted() {
      this.carregarListaTiposArquivoUpload();
    },

    data() {
      return {
        HEADER_ARQUIVO_UPLOAD,
        CODIGOS_ARQUIVO_UPLOAD,
        isLoading: false,
        itensArquivo: [],
        qteProdutosDivergencias: null,
        modalCriticas: {
          linhasComCriticas: [],
          isHidden: false,
        },
        filtroTipoArquivo: {
          selecionado: null,
          cabecalhoArquivo: null,
          opcoes: [],
        },
        FuncoesInserirItens: {
          Arquivo: {},
        },
      };
    },

    computed: {
      nomeArquivo: function () {
        if (!this.FuncoesInserirItens.Arquivo) {
          return '';
        }
        return this.FuncoesInserirItens.Arquivo.name;
      },
      balancoComLote() {
        return this.modalCriticas.linhasComCriticas.some((s) => s.Lote);
      },
    },

    methods: {
      carregarArquivo: function () {
        let files = this.$refs.arquivos.files;
        let fileExtension = files[0].name.split('.');
        fileExtension = fileExtension[fileExtension.length - 1].toLowerCase();

        if (fileExtension != 'csv' && fileExtension != 'txt') {
          swal.exibirMensagemErro(`Formato do arquivo .${fileExtension} não suportado.`);
          return;
        }
        if (window.FileReader) {
          if (files && files.length > 0) {
            this.FuncoesInserirItens.Arquivo = files[0];
          } else {
            this.FuncoesInserirItens.Arquivo = {};
          }
        } else {
          swal.exibirMensagemErro('Arquivo não suportado neste browser.');
        }
      },

      enviarArquivo() {
        var self = this;
        self.fileToJSON(self.FuncoesInserirItens.Arquivo);
      },

      fileToJSON(fileToRead) {
        let reader = new FileReader();
        reader.readAsText(fileToRead);
        reader.onload = this.loadHandler;
        reader.onerror = this.errorHandler;
      },

      loadHandler(event) {
        let file = event.target.result;
        this.processFile(file);
      },

      processFile(file) {
        try {
          let lines = file.split('\n');
          let sep = ';';
          let resultJson = [];
          let headers = this.filtroTipoArquivo.cabecalhoArquivo.split(sep);

          for (let i = 0; i < lines.length; i++) {
            let currentline = lines[i].replace(/[\r\n\t\s+]/g, '').split(sep);

            if (currentline[0].length) {
              let obj = {};
              for (let j = 0; j < headers.length; j++) {
                let property = currentline[j]?.replace('\r', '');
                let dataSaida = null;

                //Se for uma data valida, devemos converter para o formato de data e chamar a API
                let datavalida = headers[j]?.includes('Data') && this.$moment(property, 'DDMMYYYY', true).isValid();
                if (datavalida) {
                  dataSaida = this.$moment(property, 'DDMMYYYY').format();
                }

                if (headers[j]?.includes('Quantidade')) 
                  property = this.formatarValorQtde(property);

                obj['LinhaArquivo'] = i + 1;
                obj[headers[j]?.replace('\r', '')] = datavalida ? dataSaida : property;
              }
              resultJson.push(obj);
            }
          }

          this.itensArquivo = resultJson;
          this.validarArquivo();
        } catch (erro) {
          let mensagemErro = '';

          if (erro.response) {
            if (erro.response.status == 400) mensagemErro = erro.data;
            else mensagemErro = erro.message;
          } else mensagemErro = erro;

          swal.exibirMensagemErro(`Não foi possível enviar o arquivo. Erro: ${mensagemErro}`);
          return Promise.reject(mensagemErro);
        }
      },
      async validarArquivo() {
        var self = this;
        let params = {
          IdBalanco: self.dadosExecucaoBalanco.IdBalanco,
          IdSecao: self.dadosExecucaoBalanco.SecaoSelecionada.Id,
          IdEtapa: self.dadosExecucaoBalanco.SecaoSelecionada.Etapa,
          TipoArquivo: self.filtroTipoArquivo.selecionado.Id,
          ItensArquivoUpload: self.itensArquivo,
        };

        self.isLoading = true;
        await self.$store
          .dispatch('balanco/validarArquivoUploadBalanco', params)
          .then((response) => {
            //criticas
            self.modalCriticas.linhasComCriticas = response.ItensBalanco.filter((f) => f.MensagemCritica);
            self.qteProdutosDivergencias = response.ItensBalanco.length;
            if (self.modalCriticas.linhasComCriticas?.length) {
              self.modalCriticas.isHidden = true;
            } else {
              swal.exibirMensagemSucesso('Arquivo importado com sucesso!', function () {
                EventBus.$emit('finalizou-execucao-balanco');
                self.fechar();
              });
            }
          })
          .catch((erro) => {
            let mensagemErro = '';

            if (erro.response) {
              if (erro.response.status == 400) mensagemErro = erro.data;
              else mensagemErro = erro.message;
            } else mensagemErro = erro;

            swal.exibirMensagemErro(mensagemErro);
            return Promise.reject(mensagemErro);
          })
          .finally(() => {
            this.isLoading = false;
          });
      },
      errorHandler(evt) {
        if (evt.target.error.name == 'NotReadableError') {
          swal.exibirMensagemErro('Não foi possível abrir o arquivo.');
        }
      },
      carregarListaTiposArquivoUpload() {
        Object.entries(this.dadosExecucaoBalanco.TiposArquivoUpload).forEach(([key, value]) => {
          if (!isNaN(key)) this.filtroTipoArquivo.opcoes.push({ Id: key, Descricao: value });
        });
      },
      fechar() {
        EventBus.$emit('fechar-modal-execucao-qrcode');
      },
      cabecalhoTipoArquivo() {
        let listaTipos = Object.keys(this.CODIGOS_ARQUIVO_UPLOAD).map((key) => {
          return { key: key, val: this.CODIGOS_ARQUIVO_UPLOAD[key] };
        });
        let tipo = listaTipos.find((f) => f.val == this.filtroTipoArquivo?.selecionado?.Id)?.key;
        this.filtroTipoArquivo.cabecalhoArquivo = this.HEADER_ARQUIVO_UPLOAD[tipo];
      },
      removerArquivo() {
        this.FuncoesInserirItens.Arquivo = {};
        this.itensArquivo = [];
      },
      fecharModalCriticas() {
        this.modalCriticas.isHidden = false;
        this.removerArquivo();
      },
      formatarValorQtde(value) {
				var self = this;
				return new Intl.NumberFormat('en-US', {
					maximumFractionDigits: self.$store.state.balanco.parametroCasasDecimaisQuantidadeProduto,
					roundingMode: "trunc",
				}).format(parseFloat(value.replace(',', '.')));
			},
    },
  };
</script>

<style scoped>
  .circle {
    height: 70px;
    width: 70px;
    background-color: #5b2e90;
    border-radius: 50%;
    border-color: rgb(71, 70, 70);
    border-style: solid;
    border-width: 1px;
  }
  .execucao {
    color: #fff;
  }
  .card-personalizado {
    border: 1px solid #999;
    border-radius: 4px;
    background: #eee;
    text-align: center;
    cursor: pointer;
  }

  .title-card-as-button {
    font-family: 'Dosis', sans-serif;
    font-weight: 500;
    font-size: 22px;
  }

  #card-add:hover {
    opacity: 1;
    background-color: #bdbdbd;
    transition: all 0.3s ease;
  }
  #card-upload:hover {
    opacity: 1;
    background-color: #bdbdbd;
    transition: all 0.3s ease;
  }

  .card-id {
    position: absolute;
    right: 0px;
    cursor: pointer;
  }
  .multiselect__content-wrapper /*, .multiselect__element */ {
    width: fit-content;
  }
  .label-orange {
    text-align: center;
    font-family: Dosis, sans-serif;
    color: #ffb200;
    font-weight: bolder;
  }
  /*Session Loading style*/
  #loading-estoque-loading-overlay {
    position: absolute;
    top: -140px;
    left: 0px;
    height: calc(100% + 164px);
    width: 100%;
    z-index: 99999;

    background-color: rgba(0, 0, 0, 0.8);
    opacity: 0.9;
    overflow: hidden;
  }
  #loading-estoque-loading-center-spinner {
    position: relative;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -30%);
    height: 60px;
    width: 60px;
  }
  #loading-estoque-spinner {
    width: 80px;
    height: 80px;
  }
  #loading-estoque-double-bounce1,
  #loading-estoque-double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #333;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;

    -webkit-animation: sk-bounce 2s infinite ease-in-out;
    animation: sk-bounce 2s infinite ease-in-out;
  }
  #loading-estoque-double-bounce1 {
    background-color: #ffb200;
  }
  #loading-estoque-double-bounce2 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
    background-color: #fff;
  }
  @-webkit-keyframes sk-bounce {
    0%,
    100% {
      -webkit-transform: scale(0);
    }
    50% {
      -webkit-transform: scale(1);
    }
  }
  @keyframes sk-bounce {
    0%,
    100% {
      transform: scale(0);
      -webkit-transform: scale(0);
    }
    50% {
      transform: scale(1);
      -webkit-transform: scale(1);
    }
  }
</style>
