<template>
  <div v-if="isLoading">
    <BaseLoading />
  </div>
  <div v-else>
    <BaseModal
      @close="fechar()"
      :classesAdicionaisModal="'modal-balanco rounded overflow-x-hidden w-90 w-sm-100 position-relative'"
      :tituloModal="'ACOMPANHAMENTO BALANÇO'"
      :classesAdicionaisHeader="'d-flex align-items-center cabecalho-modal-customizado-lmx py-1 px-2'"
      :classesAdicionaisFooter="'d-none'"
      :classesAdicionaisBody="'py-2 overflow-x-hidden'"
      id="modalAcompanharBalanco"
    >
      <div slot="body">
        <div class="mx-3 my-4">
          <div class="row">
            <div class="d-flex justify-content-between align-items-center flex-grow-1">
              <h2 style="font-size: 22px">Balanço {{ balancoSelecionado.IdBalanco }}: {{ balancoSelecionado.Descricao }}</h2>
              <button
                class="btn btn-success mr-1"
                @click="EventBus.$emit('obter-dados-iniciais-acompanhamento')"
                v-tooltip.bottom="
                  tooltipStyle('Atualizar Acompanhamento', {
                    arrowStyle: 'arrow-tooltip-btn-atualizar',
                    innerStyle: 'inner-tooltip-btn-atualizar',
                  })
                "
              >
                <i class="fas fa-sync-alt"></i>
              </button>
            </div>
          </div>
          <div class="row mt-2 mb-2">
            <div class="col-12 d-flex">
              <div class="pr-2 mt-1 text-middle">
                <label>Visualizar</label>
              </div>
              <div class="col-6">
                <Multiselect
                  v-model="filtroStatus.selecionado"
                  :options="filtroStatus.opcoes"
                  :multiple="false"
                  :close-on-select="true"
                  placeholder="Selecione..."
                  select-label="Selecionar"
                  deselect-label="Remover"
                  selected-label="Selecionado"
                  label="Descricao"
                  @input="filtrarSecoes()"
                >
                  <template slot="selection" slot-scope="select">
                    <span class="multiselect__single" v-if="select.values.length == 1 && !select.isOpen">
                      {{ select.values[0].Descricao }}
                    </span>
                    <span class="multiselect__single" v-if="select.values.length > 1 && !select.isOpen">
                      {{ select.values.length }} selecionados
                    </span>
                  </template>
                  <span slot="noResult">Nenhum registro encontrado</span>
                  <span slot="noOptions">Selecione um Status</span>
                </Multiselect>
              </div>
            </div>
          </div>
          <div class="row">
            <label>Seções</label>
          </div>
          <div class="row">
            <!-- Tabela -->
            <div class="row flex-grow-1 mt-2">
              <div class="col-12">
                <div class="table-responsive mb-3">
                  <table class="table table-sm table-striped table-hover lmx-tabela-relatorio" cellspacing="1">
                    <thead>
                      <tr>
                        <th class="text-center align-middle">Seção</th>
                        <th class="text-center align-middle">Total de Produtos</th>
                        <th class="text-center align-middle" v-if="dadosAcompanhamento.UtilizaConferenciaEmEtapas">Etapa</th>
                        <th class="text-center align-middle">Situação</th>
                        <th class="text-center align-middle">{{ labelDivergencia }}</th>
                        <th class="text-center align-middle" v-if="controlaLocalizacao">{{ labelDivergenciaWMS }}</th>
                        <th class="text-center align-middle">Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-for="(secao, index) in listaSecoes">
                        <tr
                          class="cursor-pointer"
                          :key="index"
                          @click="selecionarSecaoGrid(secao)"
                          :class="{ 'secao-selecionada': secaoSelecionada == secao }"
                        >
                          <td class="text-center align-middle">{{ secao.Descricao }}</td>
                          <td class="text-center align-middle">
                            {{ obterTotalDeProdutosSecao(secao) }}
                          </td>
                          <td class="text-center align-middle" v-if="dadosAcompanhamento.UtilizaConferenciaEmEtapas">{{
                            secao.Etapa
                          }}</td>
                          <td class="text-center align-middle">{{ obterDescricaoStatus(secao.IdStatusSecao) }}</td>
                          <td class="text-center align-middle">{{ formatarValorQtde(secao.Divergencias) }}</td>
                          <td class="text-center align-middle" v-if="controlaLocalizacao">{{ secao.DivergenciasWMS }}</td>
                          <td class="text-center align-middle">
                            <button
                              class="btn btn-primary mr-1"
                              data-toggle="#visualizarSecaoModal"
                              @click.stop="abrirEditarSecao(secao)"
                              data-target="#visualizarSecaoModal"
                              v-tooltip.bottom="tooltipStyle(permissoes.VisualizarSecaoNoBalancoEmAndamento ? 'Visualizar Seção' : 'Sem Permissão')"
                              :disabled="!(secao && secao.PermiteEditar && !UI.editarSecao)"
                              id="button-visualizar-secao"
                            >
                              <i class="fas fa-eye"></i>
                            </button>
                            <button
                              class="btn btn-primary mr-1"
                              data-toggle="#edicaoSecaoModal"
                              @click.stop="adicionarNovosProdutos(secao)"
                              data-target="#edicaoSecaoModal"
                              v-tooltip.bottom="tooltipStyle(permissoes.EditarSecaoNoBalancoEmAndamento ? 'Editar Seção' : 'Sem Permissão')"
                              :disabled="!secao"
                              id="button-editar-secao"
                            >
                              <i class="fas fa-pen"></i>
                            </button>
                            <button
                              class="btn btn-danger"
                              @click.stop="limparDadosSecao(secao)"
                              v-tooltip.bottom="
                                tooltipStyle(permissoes.LimparSecaoNoBalancoEmAndamento ? 'Limpar Seção' : 'Sem Permissão', {
                                  arrowStyle: 'arrow-tooltip-btn-limpar-secao',
                                  innerStyle: 'inner-tooltip-btn-limpar-secao',
                                })
                              "
                              :disabled="!(secao && secao.PermiteExcluir)"
                              id="button-limpar-secao"
                            >
                              <i class="fas fa-eraser"></i>
                            </button>
                          </td>
                        </tr>
                        <tr
                          :key="'_' + index"
                          v-if="dadosAcompanhamento.UtilizaConferenciaEmEtapas && secao.Etapas && secaoSelecionada == secao"
                        >
                          <td colspan="12">
                            <div class="table-responsive mb-3">
                              <table class="table table-sm table-striped table-hover lmx-tabela-relatorio" cellspacing="1">
                                <thead>
                                  <tr class="table-light">
                                    <th class="text-center align-middle">Etapa</th>
                                    <th class="text-center align-middle">Situação</th>
                                    <th class="text-center align-middle">{{ labelDivergencia }}</th>
                                    <th class="text-center align-middle" v-if="controlaLocalizacao">{{ labelDivergenciaWMS }}</th>
                                    <th class="text-center align-middle">Ações</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(etapa, idx) in secao.Etapas" class="table-light cursor-pointer" :key="idx">
                                    <td class="text-center align-middle">
                                      {{ etapa.Etapa }}
                                    </td>
                                    <td class="text-center align-middle">
                                      <i v-if="etapa.Etapa == 3 && !terceiraEtapaLiberada(secao)" class="fas fa-minus"></i
                                      ><span v-else>{{ obterDescricaoStatus(etapa.IdStatusSecao) }}</span>
                                    </td>
                                    <td class="text-center align-middle">
                                      <i v-if="etapa.Etapa == 3 && !terceiraEtapaLiberada(secao)" class="fas fa-minus"></i
                                      ><span v-else>{{ etapa.Divergencias }}</span>
                                    </td>
                                    <td class="text-center align-middle" v-if="controlaLocalizacao">
                                      <i v-if="etapa.Etapa == 3 && !terceiraEtapaLiberada(secao)" class="fas fa-minus"></i
                                      ><span v-else>{{ etapa.DivergenciasWMS }}</span>
                                    </td>
                                    <td class="text-center align-middle">
                                      <i v-if="etapa.Etapa == 3 && !terceiraEtapaLiberada(secao)" class="fas fa-minus"></i>
                                      <div v-else>
                                        <i
                                          v-show="
                                            secao.Etapas[0].IdStatusSecao != statusSecao.concluido &&
                                            etapa.Etapa == 2 &&
                                            etapa.IdStatusSecao == statusSecao.pendente &&
                                            !secao.LiberaSegundaConferencia
                                          "
                                          class="fas fa-lock text-primary mr-1"
                                        ></i>
                                        <button
                                          v-show="
                                            etapa.IdStatusSecao < statusSecao.concluido &&
                                            (etapa.Etapa != 2 ||
                                              secao.Etapas[0].IdStatusSecao == statusSecao.concluido ||
                                              secao.LiberaSegundaConferencia) &&
                                            (etapa.Etapa != 3 || terceiraEtapaLiberada(secao))
                                          "
                                          class="btn btn-default mr-1"
                                          v-tooltip.bottom="tooltipStyle(permissoes.ExecutarBalanco ? 'Conferência' : 'Sem Permissão')"
                                          @click="abrirModalExecucaoBalanco(secao, etapa.Etapa)"
                                        >
                                          <i class="far fa-clipboard text-default"></i>
                                        </button>
                                        <button
                                          v-show="
                                            (etapa.Criticas || etapa.Divergencias > 0) &&
                                            etapa.IdStatusSecao != statusSecao.pendente &&
                                            (etapa.Etapa != 3 || terceiraEtapaLiberada(secao))
                                          "
                                          class="btn btn-default mr-1"
                                          v-tooltip.bottom="tooltipStyle('Ver Críticas')"
                                          @click="abrirCriticas(etapa)"
                                        >
                                          <i class="fas fa-lg fa-exclamation text-danger"></i>
                                        </button>
                                        <button
                                          v-show="
                                            etapa.IdStatusSecao != statusSecao.concluido &&
                                            (etapa.Etapa != 3 || terceiraEtapaLiberada(secao))
                                          "
                                          class="btn btn-default mr-1"
                                          v-tooltip.bottom="tooltipStyle('Listagem')"
                                          @click="listagemConferencia(etapa)"
                                        >
                                          <i class="far fa-lg fa-file-excel text-primary"></i>
                                        </button>
                                        <button
                                          v-show="
                                            etapa.IdStatusSecao == statusSecao.emConferencia &&
                                            dadosAcompanhamento.PermiteFinalizar
                                          "
                                          class="btn btn-default ml-1 mr-1"
                                          v-tooltip.bottom="tooltipStyle('Finalizar')"
                                          @click="finalizarExecucaoEtapa(etapa)"
                                        >
                                          <i class="far fa-lg fa-check-circle text-success"></i>
                                        </button>
                                        <button
                                          v-show="etapa.PermiteExcluir"
                                          class="btn btn-danger"
                                          v-tooltip.bottom="tooltipStyle('Limpar Etapa')"
                                          @click="limparDadosEtapa(etapa)"
                                        >
                                          <i class="fas fa-eraser"></i>
                                        </button>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-right px-2">
              <button
                type="button"
                class="btn btn-secondary mx-1"
                :disabled="!isExecutaSecao"
                v-tooltip.top="tooltipStyle(!permissoes.ExecutarBalanco ? 'Sem Permissão' : '')"
                @click="abrirModalExecucaoBalanco(secaoSelecionada)"
                >Executar</button
              >
              <button
                type="button"
                class="btn btn-primary mx-1"
                :disabled="!habilitarFinalizar"
                v-tooltip.top="tooltipStyle(!permissoes.FinalizarBalanco ? 'Sem Permissão' : '')"
                @click="finalizarBalanco()"
              >
                Finalizar
              </button>
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
    <Criticas v-if="UI.criticas" :criticas="criticas" />
    <EditarSecao v-if="UI.editarSecao" :secao-selecionada="editarSecao" :balanco-selecionado="balancoSelecionado" />
  </div>
</template>

<script>
  import Vue from 'vue';
  import VTooltip from 'v-tooltip';

  import EditarSecao from './EditarSecao';
  import Criticas from './Criticas';

  import swal from '@/common/alerts';
  import loading from '@/common/loading';
  import Multiselect from 'vue-multiselect';
  import { EventBus } from '@/common/eventBus';

  Vue.use(VTooltip);

  export default {
    name: 'ModalAcompanhamentoBalanco',

    components: {
      EditarSecao,
      Criticas,
      Multiselect,
    },

    props: {
      balancoSelecionado: {
        type: Object,
        default: () => {
          return { IdBalanco: 0, Descricao: null };
        },
      },
      dadosAcompanhamento: {
        type: Object,
        default: null,
      },
      permissoes: {
        type: Object,
        default: () => { 
          return {}; 
        }
      },
    },

    mounted() {
      this.registrarEventos();
      this.filtroStatus.selecionado = this.filtroStatus.opcoes[0];
      this.filtrarSecoes();
    },

    data() {
      return {
        secaoSelecionada: null,
        editarSecao: null,
        criticas: {},
        EventBus,
        UI: {
          isHidden: true,
          editarSecao: false,
          criticas: false,
          loadingCriticas: false,
        },
        statusSecao: {
          pendente: 4,
          emConferencia: 5,
          concluido: 6,
        },
        listaSecoes: null,
        filtroStatus: {
          selecionado: null,
          opcoes: [
            { Id: 9, Descricao: 'Todos' },
            { Id: 4, Descricao: 'Pendente' },
            { Id: 5, Descricao: 'Em Conferência' },
            { Id: 6, Descricao: 'Concluído' },
          ],
        },
      };
    },

    computed: {
      isExecutaSecao() {
        return (
          this.secaoSelecionada &&
          this.secaoSelecionada.IdStatusSecao < this.statusSecao.concluido &&
          !this.dadosAcompanhamento.UtilizaConferenciaEmEtapas
        );
      },
      habilitarFinalizar() {
        return this.dadosAcompanhamento.SecoesParaAcompanhamento.every((secao) => secao.IdStatusSecao == 6);
      },
      isLoading() {
        return this.UI.loadingCriticas;
      },
      labelDivergencia() {
        let label = this.dadosAcompanhamento.DesconsiderarDiferencas ? 'Saldo Adicionado' : 'Divergências';
        return this.controlaLocalizacao ? `${label} Depósito` : label;
      },
      labelDivergenciaWMS() {
        return this.dadosAcompanhamento.DesconsiderarDiferencas ? 'Saldo Adicionado WMS' : 'Divergências WMS';
      },
      controlaLocalizacao() {
        return this.dadosAcompanhamento.SecoesParaAcompanhamento.some((secao) => secao.ControlaLocalizacao);
      },
    },

    watch: {},

    methods: {
      fechar() {
        EventBus.$emit('fechar-modal-acompanhamento');
      },
      abrirModalExecucaoBalanco(secao, etapa) {
        if (!this.permissoes.ExecutarBalanco)
          return;
        secao = secao || this.secaoSelecionada;
        var secaoExecucao = {
          Id: secao.Id,
          Etapa: etapa,
          EsconderSeletor: true,
        };
        EventBus.$emit('abrir-modal-execucao-balanco', secaoExecucao);
        this.fechar();
      },
      abrirEditarSecao(secao) {
        if (this.permissoes.VisualizarSecaoNoBalancoEmAndamento) {
          this.editarSecao = secao;
          this.UI.editarSecao = true;
        }
      },
      fecharEditarSecao() {
        this.UI.editarSecao = false;
      },
      abrirCriticas(etapa) {
        var self = this;
        const parametros = {
          IdBalanco: self.balancoSelecionado.IdBalanco,
          IdSecao: etapa.Id,
          Etapa: etapa.Etapa,
        };
        self.UI.loadingCriticas = true;
        self
          .obterDadosCriticas(parametros)
          .then((dados) => {
            self.UI.criticas = true;
            self.criticas = dados;
          })
          .finally(() => {
            self.UI.loadingCriticas = false;
          });
      },
      obterDadosCriticas(parametros) {
        return new Promise((resolve, reject) => {
          this.$store
            .dispatch('balanco/listarCriticasSecao', parametros)
            .then((jsonResponse) => {
              return resolve(jsonResponse);
            })
            .catch((erro) => {
              let mensagemErro = '';

              if (erro.response) {
                if (erro.response.status == 400) mensagemErro = erro.data;
                else mensagemErro = erro.message;
              } else mensagemErro = erro;

              swal.exibirMensagemErro(mensagemErro);
              return Promise.reject(mensagemErro);
            });
        });
      },
      fecharCriticas() {
        this.UI.criticas = false;
      },
      download(body, nomeArquivo, nomeAba) {
        nomeAba = nomeAba || 'Pasta';
        var tableContent = `<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">
				<head> 
					<!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>${nomeAba}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->
					<meta charset="UTF-8"/>
					<style>.int{ mso-number-format:"0";}</style>
				</head>
				<body>${body}</body>
				</html>`;
        if (window.navigator.msSaveOrOpenBlob) {
          let blob = new Blob([tableContent], {
            type: 'application/vnd.ms-excel',
          });
          window.navigator.msSaveBlob(blob, nomeArquivo);
        } else {
          let blob = new Blob([tableContent], {
            type: 'application/vnd.ms-excel',
          });
          var elem = window.document.createElement('a');
          const data = window.URL.createObjectURL(blob);
          elem.href = data;
          elem.download = nomeArquivo;
          document.body.appendChild(elem);
          elem.click();
          document.body.removeChild(elem);
          setTimeout(function () {
            window.URL.revokeObjectURL(data);
          }, 100);
        }
      },
      listagemConferencia(etapa) {
        var self = this;
        const parametros = {
          IdBalanco: self.balancoSelecionado.IdBalanco,
          IdSecao: etapa.Id,
          Etapa: etapa.Etapa,
        };
        loading.exibir();
        return this.$store
          .dispatch('balanco/listarItensConferenciaSecao', parametros)
          .then((data) => {
            if (!data || !data.IdBalanco) {
              swal.exibirMensagemErro('Dados não encontrados para a etapa');
              return;
            }
            const nomeArquivo = `ListagemConferencia_${parametros.IdBalanco}_${etapa.Id}_${etapa.Etapa}.xls`;
            var table = `<h2>Listagem para Conferência do Balanço</h2><h3>Balanço: ${data.Descricao}</h3><h3>Seção: ${
              data.DescricaoSecao
            } ${data.Etapa ? ' - Etapa: ' + data.Etapa : ''}</h3>`;
            if (data.ControlaLocalizacao) {
              const linhas = data.Itens.map((i) => {
                return `<tr><td>${i.CodigoProduto}</td><td>${i.NomeProduto}</td><td>${i.Referencia || ''}</td><td class="int">${
                  i.Codebar || ''
                }</td><td>${i.SiglaLocalizacao || ''}</td><td></td></tr>`;
              }).join('');
              table = `${table}<table><thead><tr><th>Código</th><th>Nome</th><th>Referência</th><th>Cod. Barras</th><th>Localização</th><th>Quantidade</th></tr><thead><tbody>${linhas}</tbody></table>`;
            } else {
              const linhas = data.Itens.map((i) => {
                return `<tr><td>${i.CodigoProduto}</td><td>${i.NomeProduto}</td><td>${i.Referencia || ''}</td><td class="int">${
                  i.Codebar || ''
                }</td><td></td></tr>`;
              }).join('');
              table = `${table}<table><thead><tr><th>Código</th><th>Nome</th><th>Referência</th><th>Cod. Barras</th><th>Quantidade</th></tr><thead><tbody>${linhas}</tbody></table>`;
            }
            self.download(table, nomeArquivo);
            return Promise.resolve();
          })
          .catch((erro) => {
            let mensagemErro = '';

            if (erro.response) {
              if (erro.response.status == 400) mensagemErro = erro.data;
              else mensagemErro = erro.message;
            } else mensagemErro = erro;

            swal.exibirMensagemErro(mensagemErro);
            return Promise.reject(mensagemErro);
          })
          .finally(() => {
            loading.ocultar();
          });
      },
      finalizarBalanco() {
        if (!this.permissoes.FinalizarBalanco)
          return;
        this.$router.push({
          name: 'FinalizarBalanco',
          params: { 
            idBalanco: this.balancoSelecionado.IdBalanco, 
            descricao: this.balancoSelecionado.Descricao 
          },
        });
      },
      filtrarSecoes() {
        var selecionado = this.filtroStatus.selecionado.Id;
        this.listaSecoes = this.dadosAcompanhamento.SecoesParaAcompanhamento.filter(function (s) {
          return selecionado === 9 || s.IdStatusSecao === selecionado;
        });
      },
      obterDescricaoStatus(idStatus) {
        return this.filtroStatus.opcoes.find(function (s) {
          return s.Id === idStatus;
        }).Descricao;
      },
      tooltipStyle(content, classPersonalizada) {
        return {
          content,
          template: `
					<div class="tooltip-vue" role="tooltip">
						<div class="tooltip-vue-arrow ${classPersonalizada && classPersonalizada.arrowStyle}"></div>
						<div class="tooltip-vue-inner ${
              classPersonalizada && classPersonalizada.innerStyle
            }" style="border-radius: 10px; font-size: 0.8rem"></div>
					</div>
				`,
          arrowSelector: '.tooltip-vue-arrow, .tooltip-vue__arrow',
          innerSelector: '.tooltip-vue-inner, .tooltip-vue__inner',
        };
      },
      selecionarSecaoGrid(secao) {
        var self = this;

        if (self.secaoSelecionada && secao.Id == self.secaoSelecionada.Id) return (self.secaoSelecionada = null);
        self.secaoSelecionada = secao;
      },
      limparDadosEtapa(etapa) {
        const { IdBalanco } = this.balancoSelecionado;
        const command = {
          IdBalanco,
          IdSecao: etapa.Id,
          Etapa: etapa.Etapa,
        };

        loading.exibir();
        return this.$store
          .dispatch('balanco/limparDadosConferenciaSecao', command)
          .then((jsonResponse) => {
            swal.exibirMensagemSucesso('Limpeza bem sucedida!');
            EventBus.$emit('obter-dados-iniciais-acompanhamento');
            return Promise.resolve(jsonResponse);
          })
          .catch((erro) => {
            let mensagemErro = '';

            if (erro.response) {
              if (erro.response.status == 400) mensagemErro = erro.data;
              else mensagemErro = erro.message;
            } else mensagemErro = erro;

            swal.exibirMensagemErro(mensagemErro);
            return Promise.reject(mensagemErro);
          })
          .finally(() => {
            loading.ocultar();
          });
      },
      limparDadosSecao(secao) {
        if (this.permissoes.LimparSecaoNoBalancoEmAndamento) {
          swal.exibirMensagemComPergunta(
            "Atenção !",
            "Este processo não poderá ser revertido! Realmente deseja realizar a limpeza dos dados?",
            (limpar) => {
              if(limpar) this.limparDadosEtapa({
                Id: secao.Id,
                Etapa: null,
              });
            },
          );
        }        
      },
      finalizarExecucaoEtapa(etapa, confirmaDivergentes) {
        var self = this;
        confirmaDivergentes = confirmaDivergentes || false;
        loading.exibir();
        const command = {
          IdBalanco: self.balancoSelecionado.IdBalanco,
          IdSecao: etapa.Id,
          Etapa: etapa.Etapa,
          ValidarDivergentes: etapa.Etapa === 3 && !confirmaDivergentes,
        };
        return self.$store
          .dispatch('balanco/finalizarExecucaoSecao', command)
          .then(() => {
            EventBus.$emit('obter-dados-iniciais-acompanhamento', 'Etapa finalizada com sucesso!');
            return Promise.resolve();
          })
          .catch((erro) => {
            let mensagemErro = '';

            if (erro.response) {
              if (erro.response.status == 400) mensagemErro = erro.data;
              else mensagemErro = erro.message;
            } else {
              if (command.ValidarDivergentes && erro.startsWith('Existem itens')) {
                swal.exibirMensagemComPergunta('Confirmação', erro + '<br>Confirma?', (sim) => {
                  if (sim) self.finalizarExecucaoSecao(etapa, true);
                });
              } else mensagemErro = erro;
            }

            Promise.reject(mensagemErro);
          })
          .finally(function () {
            loading.ocultar();
          });
      },
      registrarEventos() {
        EventBus.$on('fechar-modal-editar-secao', () => this.fecharEditarSecao());
        EventBus.$on('fechar-modal-criticas', () => this.fecharCriticas());
      },
      terceiraEtapaLiberada(secao) {
        return (
          secao.Etapas[0].IdStatusSecao == this.statusSecao.concluido &&
          secao.Etapas[1].IdStatusSecao == this.statusSecao.concluido &&
          secao.Etapa == 3
        );
      },
      adicionarNovosProdutos(secao) {
        if (this.permissoes.EditarSecaoNoBalancoEmAndamento) {
          let params = {
            idBalanco: this.balancoSelecionado.IdBalanco,
            idSessao: secao.Id,
            nomeSecao: secao.Descricao.replace(/ /g, '-'),
            etapa: secao.Etapa,
            token: this.$store.state.autenticacao.LmxAuthToken,
            urlApi: localStorage.getItem('url_suprimentos_api'),
          };
          this.$router.push({ name: 'ExecucaoSecao', query: params });
        }
      },
      obterTotalDeProdutosSecao(secao) {
        if (secao && secao.ProdutosQuantidades.length !== 0) {
          let quantidadeTotalProdutosSecao = 0;
          secao.ProdutosQuantidades.forEach((produtoSecao) => {
            quantidadeTotalProdutosSecao += Number(produtoSecao.Quantidade);
          });
          return this.formatarValorQtde(quantidadeTotalProdutosSecao);
        }

        return 0;
      },
      formatarValorQtde(value) {
        var decimais = this.$store.state.balanco.parametroCasasDecimaisQuantidadeProduto
        return new Intl.NumberFormat('pt-BR', {
          maximumFractionDigits: decimais,
          roundingMode: "trunc",
        }).format(parseFloat(value.toFixed(decimais)));
      },
    },
  };
</script>

<style>
  .secao-selecionada {
    background-color: #e4b341 !important;
  }

  .arrow-tooltip-btn-atualizar {
    border-color: #34ab56 !important;
  }

  .inner-tooltip-btn-atualizar {
    background-color: #34ab56 !important;
  }

  .arrow-tooltip-btn-limpar-secao {
    border-color: #e53a20 !important;
  }

  .inner-tooltip-btn-limpar-secao {
    background-color: #e53a20 !important;
  }
</style>
