<template>
  <div class="h-100 w-100 d-flex flex-column container-fluid card-panel bg-white shadow-sm shadow-hover">
    <template v-if="isLoading">
      <BaseLoading />
    </template>
    <template v-else>
      <!-- Filtros -->
      <div class="row">
        <div class="col-8">
          <h1 class="h1 lmx-tela-titulo d-block float-left"> Painel de Balanços </h1>
          <span
            class="badge badge-secondary float-left lmx-bagde-guia-rapido-entrada-nfe d-none d-md-block"
            style="margin-top: 6px; margin-left: 6px"
          >
            <a
              href="https://share.linx.com.br/pages/viewpage.action?pageId=220969249"
              target="_blank"
              title="Precisa de ajuda? Acesso nosso Guia r\u00e1pido!"
            >
              <i class="fas fa-book"></i>
              Guia rápido
            </a>
          </span>
        </div>
        <div class="col-4">
          <div class="float-right">
            <button class="btn btn-success mr-1" @click="atualizarListagem()">
              <i class="fas fa-sync-alt"></i>
            </button>
            <button class="btn btn-secondary" type="button" @click="filtrarPorDataEmpresa()"> Filtrar </button>
            <button type="button" 
              class="btn btn-primary mr-1"
              v-tooltip.bottom="tooltipStyle(!Permissoes.IncluirNovoBalanco ? 'Sem Permissão' : '')"
              @click="abrirIncluirBalanco"
            >
              <span class="icon icon-lx-plus">NOVO BALANÇO</span>
            </button>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-2 pr-0">
          <label class="input-required"> Data Inicial </label>
          <div class="input-group input-group-sm date">
            <DatePicker
              ref="inputdataInicial"
              class="form-control form-control-sm"
              nome="datePickerdataInicial"
              v-model="filtros.dataInicial"
              botao="btndataInicial"
            />
            <span class="input-group-append" id="btndataInicial">
              <div class="input-group-text input-group-sm bg-transparent">
                <i class="icon icon-lx-calendar"></i>
              </div>
            </span>
          </div>
        </div>
        <div class="col-2 pr-0">
          <label class="input-required"> Data Final </label>
          <div class="input-group input-group-sm date">
            <DatePicker
              ref="inputdataFinal"
              class="form-control form-control-sm"
              nome="datePickerdataFinal"
              v-model="filtros.dataFinal"
              botao="btndataFinal"
            />
            <span class="input-group-append" id="btndataFinal">
              <i class="icon icon-lx-calendar input-group-text input-group-sm bg-transparent"></i>
            </span>
          </div>
        </div>
        <div class="col-4 pr-0">
          <label>Empresa(s)</label>
          <Multiselect
            v-model="filtros.empresa"
            :options="dadosIniciais.empresas"
            :multiple="false"
            :close-on-select="true"
            :allow-empty="false"
            :group-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Selecione"
            option-Height="10"
            selectedLabel="Selecionado"
            :custom-label="customLabel"
            selectLabel="Selecionar"
            deselectLabel="Remover"
            label="Nome"
            track-by="Id"
            :preselect-first="false"
          >
            <template slot="selection" slot-scope="select">
              <span class="multiselect__single" v-if="select.values.length == 1 &amp;&amp; !select.isOpen">
                <div v-for="item in select.values" style="float: left" :key="item">
                  {{ item.Id + '-' + item.Nome }}
                </div>
              </span>
              <span class="multiselect__single" v-if="select.values.length > 1 &amp;&amp; !select.isOpen">
                {{ select.values.length }} empresas selecionadas
              </span>
            </template>
            <span slot="noResult">Nenhum registro encontrado.</span>
            <span slot="noOptions">Selecione uma empresa.</span>
          </Multiselect>
        </div>
        <div class="col-4 pl-1">
          <label class="mb-2">Palavra chave</label>
          <input
            placeholder="Pesquise pelo código ou descrição"
            class="form-control form-control-sm"
            type="text"
            v-model="filtros.palavraChave"
          />
        </div>
        <div class="col-2">
          <div class="custom-control custom-checkbox">
            <input class="custom-control-input pt-2" id="exibirFinalizado" type="checkbox" v-model="exibirFinalizados" />
            <label class="custom-control-label" for="exibirFinalizado">Finalizados</label>
          </div>
        </div>
      </div>
      <!-- Tabela -->
      <div class="row flex-grow-1 mt-3">
        <div class="col-12">
          <div
            class="table-responsive mb-3"
            :style="'height: calc(100vh - ' + (totalPaginas > 1 ? '330px' : '290px') + '; overflow-y: auto;'"
            v-if="listaDeBalancos.length > 0"
          >
            <table class="table table-sm table-striped table-hover lmx-tabela-relatorio" cellspacing="1">
              <thead>
                <tr>
                  <th class="text-left text-nowrap">Código</th>
                  <th class="text-left text-nowrap">Descrição</th>
                  <th class="text-left text-nowrap">Data Lançamento</th>
                  <th class="text-left text-nowrap">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="cursor-hand"
                  v-for="(balanco, idx) in listaDeBalancos.slice(indexInicial, indexFinal)"
                  :key="idx"
                  @click="selecionarBalancoGrid(balanco)"
                  :class="{
                    'balanco-selecionado': balancoSelecionado == balanco,
                  }"
                >
                  <td class="text-left">{{ balanco.IdBalanco }}</td>
                  <td class="text-left">{{ balanco.Descricao }}</td>
                  <td class="text-left">
                    {{ formatarDataPTBR(balanco.DataLancamento) }}
                  </td>
                  <td class="align-middle">
                    <span>
                      {{ obterDescricaoStatusBalanco(balanco.IdStatusBalanco) }}
                    </span>

                    <span v-if="balanco.IdStatusBalanco == 8 && balanco.QtdeRegistrosProcessados > 0" class="align-middle">
                      <i style="color: rgb(44, 0, 75); font-size: 15px" class="fas fa-circle-notch fa-spin fa-3x fa-fw"></i>
                      <span
                        class="badge badge-pill badge-success align-middle mb-1"
                        data-toggle="tooltip"
                        data-html="true"
                        title="Registros Processados"
                        >{{ balanco.QtdeRegistrosProcessados }}</span
                      >
                      <span
                        class="badge badge-pill badge-secondary align-middle mb-1"
                        data-toggle="tooltip"
                        data-html="true"
                        title="Total de Divergencias"
                        >{{ balanco.TotaldeDivergencias }}</span
                      >
                      <span
                        class="badge badge-pill badge-danger align-middle mb-1"
                        data-toggle="tooltip"
                        data-html="true"
                        title="Registros Com Erro"
                        >{{ balanco.QtdeRegistrosComErro }}</span
                      >

                      <span
                        class="badge badge-pill badge-default align-middle mb-1"
                        data-toggle="tooltip"
                        data-html="true"
                        title="Total de Registros"
                        >{{ balanco.TotaldeRegistros }}</span
                      >
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="d-flex row justify-content-left ml-1" v-if="totalPaginas > 1">
            <template v-for="(pagina, index) in totalPaginas">
              <button
                v-if="verificarSeExibeBotaoPagina(pagina)"
                :key="index"
                class="btn btn-default"
                :class="{ active: pagina == paginacao.paginaAtual, 'm-1': true }"
                @click="paginacao.paginaAtual = pagina"
              >
                {{ pagina }}
              </button>
              <span v-else-if="verificarSeExibeBotaoPagina(pagina - 1)" :key="index" class="m-1">...</span>
            </template>
          </div>
          <div class="h-100 w-100 lmx-tela-relatorio-div-nenhum-registro" v-if="listaDeBalancos.length == 0">
            <div class="text-center">
              <i class="fa fa-exclamation text-primary fa-2x"></i>
              <h3>Nenhum balanço encontrado</h3>
            </div>
          </div>
        </div>
      </div>
      <!-- Ações -->
      <div class="row mt-2">
        <div class="col-12 col-md-4">
          <small class="text-muted pl-2">
            Qtde. de Registros:
            <b>{{ listaDeBalancos.length }}</b>
          </small>
        </div>
        <div class="col-12 col-md-8 mt-3 mt-md-0">
          <div class="float-right">
            <button
              class="btn btn-primary"
              data-toggle="#executarBalancoModal"
              @click="abrirExecucaoBalanco"
              data-target="#executarBalancoModal"
              :disabled="!habilitarButton"
              v-tooltip.top="tooltipStyle(!Permissoes.ExecutarBalanco ? 'Sem Permissão' : '')"
            >
              Executar
            </button>
            <button
              v-if="habilitarButtonConferir"
              id="button-conferir"
              class="btn btn-primary"
              data-toggle="modal"
              v-tooltip.top="tooltipStyle(!Permissoes.ConferirBalanco ? 'Sem Permissão' : '')"
              @click="abrirConferirBalanco"
            >
              Conferir
            </button>
            <button
              v-if="acompanhamentoBalanco"
              class="btn btn-primary"
              data-toggle="#acompanhamentoBalancoModal"
              @click="abrirAcompanhamentoBalanco"
              data-target="#acompanhamentoBalancoModal"
            >
              Acompanhamento
            </button>
            <button
              v-if="habilitarButtonBalancoPlanejado"
              class="btn btn-default"
              data-toggle="modal"
              v-tooltip.top="tooltipStyle(!Permissoes.AlterarBalanco ? 'Sem Permissão' : '')"
              @click="abrirEditarBalanco"
            >
              Editar
            </button>
            <button
              class="btn btn-secondary"
              data-toggle="modal"
              :disabled="balancoSelecionado == null"
              @click="
                $router.push({
                  name: 'CadastroBalancos',
                  params: {
                    idBalanco: balancoSelecionado.IdBalanco,
                    idEmpresa: filtros.empresa.Id,
                    idStatusBalanco: balancoSelecionado.IdStatusBalanco,
                    visualizar: true
                  },
                })
              "
            >
              Visualizar
            </button>
            <button
              v-if="!ParametrosSuprimentos.UtilizaConferenciaEtapasBalancos && Permissoes.EstornarBalanco"
              :disabled="!habilitarButtonEstonar"
              class="btn btn-secondary"
              data-toggle="#estornoBalancoModal"
              @click="abrirEstornoBalanco"
              data-target="#estornoBalancoModal"
            >
              Estornar
            </button>
            <button
              v-if="!ParametrosSuprimentos.UtilizaConferenciaEtapasBalancos && !Permissoes.EstornarBalanco"
              :disabled="!habilitarButtonEstonar"
              class="btn btn-secondary"
              v-tooltip.top="tooltipStyle('Sem Permissão')"
            >
              Estornar
            </button>
            <button
              v-if="Permissoes.CancelarBalanco"
              class="btn btn-secondary"
              data-toggle="modal"
              :disabled="!habilitarButton || balancoSelecionado == null"
              @click="cancelarBalanco()"
            >
              Cancelar
            </button>
            <button
              v-else
              class="btn btn-secondary"
              :disabled="!habilitarButton || balancoSelecionado == null"
              v-tooltip.top="tooltipStyle('Sem Permissão')"
            >
              Cancelar
            </button>            
          </div>
        </div>
        <ExecucaoBalanco v-if="UI.executarBalanco" :dados-execucao-balanco="dadosExecucaoBalanco" />
        <AcompanhamentoBalanco
          v-if="UI.acompanharBalanco"
          :balanco-selecionado="balancoSelecionado"
          :dados-acompanhamento="dadosBalancoAcompanhamento"
          :permissoes="Permissoes"
        />
        <Estorno v-if="UI.estornoBalanco" :balanco-selecionado="balancoSelecionado" />
      </div>
    </template>
  </div>
</template>

<script>
  import Vue from 'vue';
  import { mapWaitingActions, mapWaitingGetters } from 'vue-wait';

  import Multiselect from 'vue-multiselect';
  import DatePicker from '@/components/shared/PikadayVueDatepicker';

  import ExecucaoBalanco from './execucao/ExecucaoBalanco';
  import AcompanhamentoBalanco from './acompanhamento/Acompanhamento';
  import Estorno from './Estorno/EstornoBalanco.vue';

  import swal from '@/common/alerts';
  import loading from '@/common/loading';
  import dateUtils from '@/common/date';

  import { EventBus } from '@/common/eventBus';
  import apiConfig from '@/services/apiConfig';
  import VTooltip from 'v-tooltip';

  Vue.use(VTooltip);

  export default {
    components: {
      Multiselect,
      DatePicker,
      ExecucaoBalanco,
      AcompanhamentoBalanco,
      Estorno,
    },

    data() {
      return {
        balancoSelecionado: null,
        dadosBalancoAcompanhamento: null,
        dadosExecucaoBalanco: null,
        registrosPorPagina: 20,
        exibirFinalizados: false,
        filtros: {
          dataInicial: '',
          dataFinal: '',
          palavraChave: '',
          empresa: '',
        },
        dadosIniciais: {
          balancos: [],
          empresas: [],
          novaListaBalanco: [],
        },
        statusBalanco: {
          planejado: 1,
          emAndamento: 2,
          finalizado: 3,
          cancelado: 7,
        },
        paginacao: {
          paginaAtual: 1,
        },
        UI: {
          executarBalanco: false,
          acompanharBalanco: false,
          estornoBalanco: false,
        },
        hubConnection: null,
        ParametrosSuprimentos: {},
        Permissoes: {},
      };
    },

    watch: {
      totalPaginas(newVal) {
        var self = this;
        if (self.paginacao.paginaAtual > newVal) self.paginacao.paginaAtual = 1;
      },
      empresaLogada() {
        var self = this;
        self.filtros.empresa = self.converterIdParaempresa();
      },
    },

    computed: {
      listaDeBalancos() {
        var self = this;
        if (self.filtros.palavraChave.trim() === '') {
          if (!self.exibirFinalizados) {
            return self.dadosIniciais.balancos.filter(function (balanco) {
              return balanco.IdStatusBalanco != 3;
            });
          }
          if (self.exibirFinalizados) {
            return self.dadosIniciais.balancos.filter(function (balanco) {
              return balanco.IdStatusBalanco == 3;
            });
          }
        }
        return self.dadosIniciais.balancos.filter(function (balanco) {
          if (
            Object.keys(balanco)
              .map(function (prop) {
                if (prop == 'IdBalanco') return balanco[prop];

                if (prop == 'Descricao') return balanco[prop];

                if (prop == 'Situacao') return [prop];

                if (prop == 'DataLancamento') return self.formatarDataPTBR(balanco[prop]);
              })
              .toString()
              .toLowerCase()
              .includes(self.filtros.palavraChave.toLowerCase().trim())
          ) {
            if (!self.exibirFinalizados) return balanco.IdStatusBalanco != 3;

            return balanco;
          }
        });
      },
      acompanhamentoBalanco() {
        var self = this;
        return (
          self.balancoSelecionado &&
          (self.balancoSelecionado.IdStatusBalanco === self.statusBalanco.emAndamento ||
            (self.balancoSelecionado.UtilizaConferenciaEmEtapas &&
              self.balancoSelecionado.IdStatusBalanco === self.statusBalanco.planejado))
        );
      },
      habilitarButton() {
        var self = this;
        return self.balancoSelecionado && self.balancoSelecionado.IdStatusBalanco != self.statusBalanco.finalizado;
      },
      habilitarButtonBalancoPlanejado() {
        var self = this;
        return self.balancoSelecionado && self.balancoSelecionado.IdStatusBalanco === self.statusBalanco.planejado;
      },
      habilitarButtonEstonar() {
        var self = this;
        return self.balancoSelecionado && self.balancoSelecionado.IdStatusBalanco === self.statusBalanco.finalizado;
      },
      habilitarButtonConferir() {
        var self = this;
        return (
          self.balancoSelecionado &&
          self.balancoSelecionado.IdStatusBalanco === self.statusBalanco.finalizado &&
          !self.balancoSelecionado.UtilizaConferenciaEmEtapas
        );
      },
      totalPaginas() {
        var self = this;
        return Math.ceil(self.listaDeBalancos.length / self.registrosPorPagina);
      },
      indexInicial() {
        var self = this;
        return self.registrosPorPagina * (self.paginacao.paginaAtual - 1);
      },
      indexFinal() {
        var self = this;
        var indexFinal = self.registrosPorPagina * (self.paginacao.paginaAtual - 1) + self.registrosPorPagina;

        return indexFinal;
      },
      isLoading() {
        return this.$store.state.uiState.isLoading;
      },
    },

    mounted() {
      this.registrarEventos();
      var urlHubs = apiConfig.getUrlSuprimentosApi() + '/signalr';
      // eslint-disable-next-line no-undef
      this.hubConnection = $.hubConnection();
      this.hubConnection.url = urlHubs;
      this.hubConnection.qs = { Authorization: localStorage.getItem('token_api') };
      this.conectarHubBalancoProdutos();
      this.obterDadosIniciaisPainelBalancos();
    },

    methods: {
      obterDadosIniciaisPainelBalancos() {
        var self = this;
        loading.exibir();
        return self.$store
          .dispatch('balanco/obterDadosIniciaisPainelBalancos')
          .then(self.popularDadosIniciais)
          .catch((erro) => {
            let mensagemErro = '';

            if (erro.response) {
              if (erro.response.status == 400) mensagemErro = erro.data;
              else mensagemErro = erro.message;
            } else mensagemErro = erro;

            swal.exibirMensagemErro(mensagemErro);
            return Promise.reject(mensagemErro);
          })
          .finally(function () {
            loading.ocultar();
          });
      },
      popularDadosIniciais(retorno) {
        var self = this;
        self.dadosIniciais.balancos = retorno.ListaDeBalancos.Balancos;
        self.filtros.dataInicial = self.formatarDataPTBR(retorno.DataInicial);
        self.filtros.dataFinal = self.formatarDataPTBR(retorno.DataFinal);
        self.dadosIniciais.empresas = retorno.Empresas;
        self.filtros.empresa = self.dadosIniciais.empresas.find(function (e) {
          return e.Id == retorno.IdEmpresaLogada;
        });
        self.ParametrosSuprimentos = retorno.ParametrosSuprimentos;
        self.Permissoes = retorno.Permissoes;
        self.$store.commit('balanco/setPermissoes', retorno.Permissoes);
        self.$store.commit('balanco/setParametroCasasDecimaisQuantidadeProduto', 
          retorno.ParametroCasasDecimaisQuantidadeProduto);
          
        return Promise.resolve();
      },
      cancelarBalanco() {
        var self = this;
        var idBalanco = self.balancoSelecionado.IdBalanco;
        var descricao = self.balancoSelecionado.Descricao;
        swal.exibirMensagemComPergunta(
          '<h3>Atenção!</h3>',
          'Este processo não poderá ser revertido.' +
            '<br>Deseja cancelar o balanço <b>' +
            idBalanco +
            ' - ' +
            descricao +
            '</b>?',
          (cancelar) => {
            if (cancelar) self.efetuarCancelamento();
          },
        );
      },
      efetuarCancelamento() {
        var self = this;
        loading.exibir();
        var command = {};
        command.IdBalanco = self.balancoSelecionado.IdBalanco;
        return self.$store
          .dispatch('balanco/cancelarBalanco', command)
          .then(function () {
            self.balancoSelecionado.IdStatusBalanco = self.statusBalanco.cancelado;
            swal.exibirMensagemSucesso('Balanço ' + command.IdBalanco + ' cancelado com sucesso!', function () {
              self.filtrarPorDataEmpresa();
            });
          })
          .catch(function (erro) {
            let mensagemErro = '';

            if (erro.response) {
              if (erro.response.status == 400) mensagemErro = erro.data;
              else mensagemErro = erro.message;
            } else mensagemErro = erro;

            swal.exibirMensagemErro(mensagemErro);
            return Promise.reject(mensagemErro);
          })
          .finally(function () {
            loading.ocultar();
          });
      },
      obterDescricaoStatusBalanco(idStatus) {
        switch (idStatus) {
          case 1:
            return 'Planejado';
          case 2:
            return 'Em andamento';
          case 3:
            return 'Finalizado';
          case 7:
            return 'Cancelado';
          case 8:
            return 'Processando';
          default:
            return '';
        }
      },
      filtrarPorDataEmpresa() {
        var self = this;
        var command = {
          idempresa: self.filtros.empresa.Id,
          dataInicial: self.converterData(self.filtros.dataInicial),
          dataFinal: self.converterData(self.filtros.dataFinal),
        };

        loading.exibir();
        return self.$store
          .dispatch('balanco/filtrarBalancos', command)
          .then(function (jsonRetorno) {
            self.dadosIniciais.balancos = jsonRetorno.Balancos;
          })
          .catch(function (erro) {
            let mensagemErro = '';

            if (erro.response) {
              if (erro.response.status == 400) mensagemErro = erro.data;
              else mensagemErro = erro.message;
            } else mensagemErro = erro;

            swal.exibirMensagemErro(mensagemErro);
            return Promise.reject(mensagemErro);
          })
          .finally(function () {
            loading.ocultar();
          });
      },
      selecionarBalancoGrid(balanco) {
        var self = this;
        if (self.balancoSelecionado && balanco.IdBalanco == self.balancoSelecionado.IdBalanco) {
          self.balancoSelecionado = null;
          return;
        }
        self.balancoSelecionado = balanco;
      },
      abrirIncluirBalanco() { 
        if (!this.Permissoes.IncluirNovoBalanco)
          return;
        this.$router.push({
          name: 'CadastroBalancos',
        });        
      },
      abrirEditarBalanco() {
        if (!this.Permissoes.AlterarBalanco)
          return;
        this.$router.push({
          name: 'CadastroBalancos',
          params: {
            idBalanco: this.balancoSelecionado.IdBalanco,
            idEmpresa: this.filtros.empresa.Id,
            idStatusBalanco: this.balancoSelecionado.IdStatusBalanco,
            visualizar: false
          },
        });
      },
      abrirExecucaoBalanco(secao) {
        if (!this.Permissoes.ExecutarBalanco)
          return;
        var self = this;
        this.dadosExecucaoBalanco = {};
        this.obterSecoesBalanco().then((balanco) => {
          self.dadosExecucaoBalanco = balanco;
          // inclui o parametro que seleciona uma secao especifica
          self.dadosExecucaoBalanco.Secao = secao;
          self.UI.executarBalanco = true;
        });
      },
      abrirConferirBalanco() {
        if (!this.Permissoes.ConferirBalanco)
          return;
        this.$router.push({
          name: 'RelatorioDeCriticas',
          params: {
            idBalanco: this.balancoSelecionado.IdBalanco,
            descricaoBalanco: this.balancoSelecionado.Descricao,
            empresas: this.filtros.empresa,
          },
        });
      },
      abrirAcompanhamentoBalanco() {
        this.obterDadosIniciaisParaAcompanhamento().then(() => {
          this.UI.acompanharBalanco = true;
        });
      },
      fecharExecucao() {
        this.UI.executarBalanco = false;
      },
      fecharAcompanhamento() {
        this.UI.acompanharBalanco = false;
      },
      montarQueryFiltros() {
        var self = this;
        var query = '?';
        var idempresa = '';
        var dataInicial = '';
        var dataFinal = '';
        var codigoOuDescricao = 'codigoOuDescricao=' + self.filtros.CodigoOuDescricao.trim();
        if (self.filtros.dataInicial != '') dataInicial = '&dataInicial=' + self.converterData(self.filtros.dataInicial);

        if (self.filtros.dataFinal != '') dataFinal = '&dataFinal=' + self.converterData(self.filtros.dataFinal, true);

        if (self.filtros.empresa != null) idempresa = '&idempresa=' + self.filtros.empresa.Id;

        query += codigoOuDescricao + idempresa + dataInicial + dataFinal;
        return query;
      },
      obterDataFiltros() {
        var self = this;
        var novaData = new Date();
        var dataInicial = new Date();
        dataInicial.setDate(novaData.getDate() - 90);
        self.filtros.dataInicial = dataInicial.toLocaleDateString().replace(/[^ -~]/g, '');
        self.filtros.dataFinal = novaData.toLocaleDateString().replace(/[^ -~]/g, '');
      },
      verificarSeExibeBotaoPagina(pagina) {
        var self = this;
        return (
          self.paginacao.paginaAtual == pagina ||
          self.paginacao.paginaAtual + 1 == pagina ||
          self.paginacao.paginaAtual - 1 == pagina ||
          self.totalPaginas == pagina ||
          self.totalPaginas - 1 == pagina ||
          1 == pagina ||
          2 == pagina
        );
      },
      converterData(dataString, setHora) {
        var dia = dataString.split('/')[0];
        var mes = Number(dataString.split('/')[1] - 1);
        var ano = dataString.split('/')[2];
        var data = '';
        if (setHora) data = new Date(ano, mes, dia, 23, 59, 59);
        else data = new Date(ano, mes, dia);

        return new Date(data.valueOf() - data.getTimezoneOffset() * 60000).toISOString();
      },
      customLabel(obj) {
        var Descricao;
        if (obj.Descricao) {
          Descricao = obj.Descricao;
        } else {
          Descricao = obj.Nome;
        }
        return obj.Id + ' - ' + Descricao;
      },
      formatarDataPTBR(data) {
        return dateUtils.toStringPtBr(data);
      },
      obterDadosIniciaisParaAcompanhamento(msg) {
        const { IdBalanco } = this.balancoSelecionado;

        loading.exibir();

        return new Promise((resolve, reject) => {
          this.$store
            .dispatch('balanco/obterDadosIniciaisParaAcompanhamento', { IdBalanco })
            .then((jsonResponse) => {
              this.dadosBalancoAcompanhamento = jsonResponse;
              if (msg) swal.exibirMensagemSucesso(msg);
              return resolve(jsonResponse);
            })
            .catch((erro) => {
              let mensagemErro = '';

              if (erro.response) {
                if (erro.response.status == 400) mensagemErro = erro.data;
                else mensagemErro = erro.message;
              } else mensagemErro = erro;

              swal.exibirMensagemErro(mensagemErro);
              return Promise.reject(mensagemErro);
            })
            .finally(() => {
              loading.ocultar();
            });
        });
      },
      obterSecoesBalanco() {
        const { IdBalanco } = this.balancoSelecionado;
        loading.exibir();
        return new Promise((resolve, reject) => {
          this.$store
            .dispatch('balanco/listarSecoesBalancoParaExecucao', { IdBalanco })
            .then((jsonResponse) => {
              resolve(jsonResponse);
            })
            .catch((erro) => {
              let mensagemErro = '';

              if (erro.response) {
                if (erro.response.status == 400) mensagemErro = erro.data;
                else mensagemErro = erro.message;
              } else mensagemErro = erro;

              swal.exibirMensagemErro(mensagemErro);
              return Promise.reject(mensagemErro);
            })
            .finally(() => {
              loading.ocultar();
            });
        });
      },
      registrarEventos() {
        EventBus.$on('fechar-modal-execucao', () => this.fecharExecucao());
        EventBus.$on('fechar-modal-acompanhamento', () => this.fecharAcompanhamento());
        EventBus.$on('obter-dados-iniciais-acompanhamento', (msg) => this.obterDadosIniciaisParaAcompanhamento(msg));
        EventBus.$on('abrir-modal-execucao-balanco', (secao) => this.abrirExecucaoBalanco(secao));
        EventBus.$on('recarregar-painel-balanco', () => this.obterDadosIniciaisPainelBalancos());
        EventBus.$on('fechar-modal-estorno', () => this.fecharModalEstorno());
        EventBus.$on('desabilitar-Filtro-Finalizados', () => this.desabilitarFiltroFinalizados());
      },
      atualizarStatusBalanco: function (evento) {
        var balancoParaAtualizar = this.listaDeBalancos.find(function (balanco) {
          return balanco.IdBalanco == evento.IdBalanco;
        });
        if (balancoParaAtualizar) {
          balancoParaAtualizar.QtdeRegistrosProcessados = evento.ProdutosProcessados;
          balancoParaAtualizar.QtdeRegistrosComErro = evento.QtdeRegistrosComErro;
          balancoParaAtualizar.TotaldeRegistros = evento.TotalDeRegistros;
          balancoParaAtualizar.IdStatusBalanco = evento.IdStatusBalanco;
          balancoParaAtualizar.TotaldeDivergencias = evento.TotalDeDivergencias;
        }
      },
      atualizarListagem: function () {
        this.hubConnection.stop();
        this.conectarHubBalancoProdutos();
        this.obterDadosIniciaisPainelBalancos();
      },
      conectarHubBalancoProdutos: function () {
        var self = this;

        var importacaoProdutosHubProxy = self.hubConnection.createHubProxy('balancoProdutosHub');
        importacaoProdutosHubProxy.on('atualizarStatusBalanco', this.atualizarStatusBalanco);
        var groupName = this.$store.state.autenticacao.empresa.Portal;
        self.hubConnection
          .start()
          .done(function () {
            // console.log('Conectado ao hub: connection ID=' + connection.id);

            importacaoProdutosHubProxy
              .invoke('joinGroup', groupName)
              .done(function () {
                // console.log('Entrou no grupo: ' + groupName);
              })
              .fail(function (error) {
                console.log('Error: ' + error);
              });
          })
          .fail(function () {
            self.exibirToastSwal(
              'Oops!',
              self.tipoMensagemSwal.Erro,
              'N\u00e3o foi poss\u00edvel abrir uma conex\u00e3o com o Hub!',
            );
          });
      },
      abrirEstornoBalanco() {
        this.obterListaSecaoEQuantidadedoEstornoBalanco().then(() => {
          this.UI.estornoBalanco = true;
        });
      },
      fecharModalEstorno() {
        this.UI.estornoBalanco = false;
      },
      desabilitarFiltroFinalizados() {
        var self = this;
        self.exibirFinalizados = false;
        self.balancoSelecionado = null;
      },
      obterListaSecaoEQuantidadedoEstornoBalanco() {
        const { IdBalanco } = this.balancoSelecionado;

        loading.exibir();

        return new Promise((resolve, reject) => {
          this.$store
            .dispatch('balanco/ObterSecaoEQuantidaEstorno', { IdBalanco })
            .then((jsonResponse) => {
              resolve(jsonResponse);
            })
            .catch((erro) => {
              let mensagemErro = '';

              if (erro.response) {
                if (erro.response.status == 400) mensagemErro = erro.data;
                else mensagemErro = erro.message;
              } else mensagemErro = erro;

              swal.exibirMensagemErro(mensagemErro);
              return Promise.reject(mensagemErro);
            })
            .finally(() => {
              loading.ocultar();
            });
        });
      },
      tooltipStyle(content, classPersonalizada) {
        return {
          content,
          template: `
					<div class="tooltip-vue" role="tooltip">
						<div class="tooltip-vue-arrow ${classPersonalizada && classPersonalizada.arrowStyle}"></div>
						<div class="tooltip-vue-inner ${
              classPersonalizada && classPersonalizada.innerStyle
            }" style="border-radius: 10px; font-size: 0.8rem"></div>
					</div>
				`,
          arrowSelector: '.tooltip-vue-arrow, .tooltip-vue__arrow',
          innerSelector: '.tooltip-vue-inner, .tooltip-vue__inner',
        };
      },
    },
  };
</script>

<style scoped>
  .cursor-hand {
    cursor: pointer;
  }
  .balanco-selecionado {
    background-color: #ffb200 !important;
  }
  .btn {
    height: 34px;
    margin: 4px;
  }
  a.btn {
    line-height: unset;
  }
  .multiselect__tags {
    border-radius: unset;
    height: 31px !important;
  }
  .lmx-tabela-relatorio thead,
  tfoot {
    background-color: #5b2e90;
    color: white;
  }

  .lmx-tabela-relatorio tbody {
    font-size: 90%;
  }

  .lmx-tabela-relatorio-linha-totais {
    color: #ffffff;
    font-weight: bold;
    background-color: #5b2e90;
  }

  .lmx-tela-relatorio-div-nenhum-registro {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .lmx-tela-relatorio-div-nenhum-registro i {
    font-size: 3rem;
  }
  .custom-checkbox {
    padding-top: 2rem !important;
  }
  .col-xs-1-5 {
    width: 12.49995%;
  }
  .badge {
    margin: 0.07rem;
  }
</style>
