<template>
  <div>
    <BaseModal
      @close="fechar()"
      :classesAdicionaisModal="'modal-balanco rounded overflow-x-hidden w-60 w-sm-100 position-relative'"
      :tituloModal="'EXECUÇÃO BALANÇO'"
      :classesAdicionaisBackDrop="'align-items-start'"
      :classesAdicionaisFooter="'d-none'"
      :classesAdicionaisBody="'py-2 overflow-x-hidden'"
      id="modalExecucaoBalanco"
      v-if="!UI.isHidden"
    >
      <div slot="body">
        <div class="row mt-3 mb-5">
          <div class="col-sm-15 d-flex justify-content-center w-100 mt-2">
            <h4 class="title-card-as-button mb-3"> Deseja prosseguir com a execução do balanço? </h4>
          </div>
          <div class="col-sm-15 d-flex justify-content-center w-100 mt-2">
            <div class="col-6">
              <div
                class="card card-personalizado h-100 w-100 mt-1"
                id="card-add"
                data-toggle="#executarModalQrCode"
                @click="abrirModalQrCode"
                data-target="#executarModalQrCode"
              >
                <div class="card-body">
                  <div class="d-flex justify-content-center w-100 mt-2">
                    <h5 class="card-title">
                      <span class="title-card-as-button">Online</span>
                    </h5>
                  </div>

                  <div class="d-flex justify-content-center w-100">
                    <div class="d-flex flex-row flex-wrap justify-content-center align-content-center circle align-items-center">
                      <i class="fas fa-mobile-alt fa-3x execucao pb-2"></i>
                    </div>
                  </div>
                  <div class="d-flex justify-content-center w-100 mt-2">
                    <span
                      >Utilize o seu celular ou tablet para executar o balanço de forma online, sem necessitar de coletor de
                      dados.</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <!-- a opção de upload de arquivo não atende ao fluxo de WMS -->
            <div class="col-6" v-if="!dadosExecucaoBalanco.PermissoesUsuario.UtilizaModuloWMS">
              <div
                class="card card-personalizado h-100 w-100 mt-1"
                id="card-upload"
                data-toggle="#uploadFile"
                @click="abrirModalUpload"
                data-target="#uploadFile"
              >
                <div class="card-body">
                  <div class="d-flex justify-content-center w-100 mt-2">
                    <h5 class="card-title">
                      <span class="title-card-as-button">Importação de Arquivo</span>
                    </h5>
                  </div>

                  <div class="d-flex justify-content-center w-100">
                    <div class="d-flex flex-row flex-wrap justify-content-center align-content-center circle align-items-center">
                      <i class="fas fa-upload fa-3x execucao"></i>
                    </div>
                  </div>
                  <div class="d-flex justify-content-center w-100 mt-2">
                    <span>Realize a importação de um arquivo .txt ou .csv</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseModal>
    <ExecucaoQrCode v-if="UI.executarQrCode" :dados-execucao-balanco="dadosExecucaoBalanco" :is-upload-file="isUploadFile" />
  </div>
</template>

<script>
  import ExecucaoQrCode from './ExecucaoQrCode';

  import { EventBus } from '@/common/eventBus';

  export default {
    name: 'ExecucaoBalanco',

    components: {
      ExecucaoQrCode,
    },

    props: {
      dadosExecucaoBalanco: {
        type: Object,
        default: null,
      },
    },

    mounted() {
      this.registrarEventos();
      //if (this.dadosExecucaoBalanco && this.dadosExecucaoBalanco.Secao) this.abrirModalQrCode();
    },

    data() {
      return {
        UI: {
          isHidden: false,
          executarQrCode: false,
        },
        isUploadFile: false,
      };
    },

    watch: {},

    computed: {},

    methods: {
      fechar() {
        EventBus.$emit('fechar-modal-execucao');
      },
      finalizarExecucaoBalanco() {
        EventBus.$emit('recarregar-painel-balanco');
      },
      abrirModalQrCode() {
        this.UI.isHidden = true;
        this.UI.executarQrCode = true;
      },
      fecharModalQrCode() {
        this.UI.executarQrCode = false;
        this.UI.isHidden = false;
        this.fechar();
      },
      abrirModalUpload() {
        this.isUploadFile = true;
        this.abrirModalQrCode();
      },
      registrarEventos() {
        EventBus.$on('fechar-modal-execucao-qrcode', () => this.fecharModalQrCode());
        EventBus.$on('finalizou-execucao-balanco', () => this.finalizarExecucaoBalanco());
      },
    },
  };
</script>

<style scoped>
  .circle {
    height: 70px;
    width: 70px;
    background-color: #5b2e90;
    border-radius: 50%;
    border-color: rgb(71, 70, 70);
    border-style: solid;
    border-width: 1px;
  }
  .execucao {
    color: #fff;
  }
  .card-personalizado {
    border: 1px solid #999;
    border-radius: 4px;
    background: #eee;
    text-align: center;
    cursor: pointer;
  }

  .title-card-as-button {
    font-family: 'Dosis', sans-serif;
    font-weight: 500;
    font-size: 22px;
  }

  #card-add:hover {
    opacity: 1;
    background-color: #bdbdbd;
    transition: all 0.3s ease;
  }
  #card-upload:hover {
    opacity: 1;
    background-color: #bdbdbd;
    transition: all 0.3s ease;
  }

  .card-id {
    position: absolute;
    right: 0px;
    cursor: pointer;
  }
</style>
