<template>
  <BaseModal
    @close="fechar()"
    :classesAdicionaisModal="'modal-balanco rounded overflow-x-hidden w-90 w-sm-100 position-relative'"
    :tituloModal="'CRÍTICAS'"
    :classesAdicionaisHeader="'d-flex align-items-center cabecalho-modal-customizado-lmx py-1 px-2'"
    :classesAdicionaisFooter="'d-none'"
    :classesAdicionaisBody="'py-2 overflow-x-hidden'"
    id="modalCriticas"
  >
    <div slot="body">
      <div class="mt-3 mr-5 mb-3 ml-5">
        <div id="criticasSecao">
          <div class="row">
            <h2>Balanço {{ criticas.IdBalanco }}: {{ criticas.Descricao }}</h2>
          </div>
          <div class="row mt-2">
            <h3>
              {{ criticas.DescricaoSecao }} - Etapa:
              {{ criticas.Etapa }}
            </h3>
          </div>
          <div class="row">
            <!-- Tabela -->
            <div class="row flex-grow-1 mt-2">
              <div class="col-12">
                <div class="table-responsive mb-3">
                  <table class="table table-sm table-striped table-hover lmx-tabela-relatorio" cellspacing="1">
                    <thead>
                      <tr>
                        <th v-if="criticas.ControlaLocalizacao">Localização</th>
                        <th class="text-center align-middle">Código</th>
                        <th class="text-center align-middle">Referência</th>
                        <th class="text-center align-middle">Descrição</th>
                        <th class="text-center align-middle">Saldo Estoque</th>
                        <th class="text-center align-middle">Quantidade Conferida</th>
                        <th class="text-center align-middle">Quantidade Divergente</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(produto, index) in criticas.Itens" :key="index">
                        <td v-if="criticas.ControlaLocalizacao">{{ produto.SiglaLocalizacao }}</td>
                        <td class="text-center">{{ produto.CodigoProduto }}</td>
                        <td class="text-center">{{ produto.Referencia }}</td>
                        <td class="text-center">{{ produto.Nome }}</td>
                        <td class="text-center">{{ produto.Saldo }}</td>
                        <td class="text-center">{{ produto.QuantidadeTotalConferencia }}</td>
                        <td class="text-center">{{ produto.ValorAjuste }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 d-flex justify-content-between px-2">
            <button type="button" class="btn btn-default rounded" @click="fechar">Fechar</button>
            <button type="button" class="btn btn-primary rounded" @click="imprimir">Imprimir</button>
          </div>
        </div>
      </div>
    </div>
  </BaseModal>
</template>

<script>
  import Vue from 'vue';
  import VueHtmlToPaper from 'vue-html-to-paper';

  import { EventBus } from '@/common/eventBus';

  const options = {
    name: '_blank',
    specs: ['fullscreen=yes', 'titlebar=no', 'scrollbars=yes'],
    timeout: 1000, // default timeout before the print window appears
    autoClose: true, // if false, the window will not close after printing
    windowTitle: window.document.title, // override the window title
  };

  Vue.use(VueHtmlToPaper, options);

  export default {
    name: 'ModalCriticasSecaoBalanco',

    components: {},

    props: {
      criticas: {
        type: Object,
        default: () => {},
      },
    },

    mounted() {},

    data() {
      return {};
    },

    watch: {},

    methods: {
      fechar() {
        EventBus.$emit('fechar-modal-criticas');
      },
      imprimir() {
        this.$htmlToPaper('criticasSecao');
      },
    },
  };
</script>
